<template>
	<!-- 监理周报 -->
	<div class="mod-config">
		<div ref="basic" style="height:20px;margin-top:-20px"></div>
		<el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="auto" :inline="true" class="border-form">
			<el-card style="margin-top: 20px; height: 100%;">
				<el-row>
					<el-input class="wn" v-model="dataForm.monthlyName" placeholder="请输入"
										v-if="basicVisible">
					</el-input>
					<span v-if="!basicVisible" style="font-weight: bold;font-size:16px">
						{{ dataForm.monthlyName }}
					</span>
				</el-row>
				<el-row>
					<div class="set_title">监理月报</div>
				</el-row>
				<el-row>
					<div class="set_term">第 {{ dataForm.monthlyTerm }} 期</div>
				</el-row>
				<el-row>
					<div class="set_date">{{dataForm.showMonthlyDate}}</div>
				</el-row>
				<el-row>
					<el-col :span="16">
						<el-row class="set_border">
							<el-col :span="12" class="col-set_border">
								<el-form-item label-width="120px" label="项目名称" class="item">
									<span class="set_span">
										{{ dataForm.projectName }}
									</span>
								</el-form-item>
							</el-col>
							<el-col :span="12">
								<el-form-item label-width="120px" label="数量" class="item">
									<el-input class="sp-input" v-model="dataForm.projectNum" placeholder="请输入"
										v-if="basicVisible">
									</el-input>
									<span class="set_span" v-if="!basicVisible">
										{{ dataForm.projectNum }}
									</span>
								</el-form-item>
							</el-col>
						</el-row>
						<el-row class="set_border">
							<el-col :span="24">
								<el-form-item label-width="120px" label="委托方" class="item">
									<span class="set_span">
										{{ dataForm.projectEntrust }}
									</span>
								</el-form-item>
							</el-col>
						</el-row>
						<el-row class="set_border">
							<el-col :span="12" class="col-set_border">
								<el-form-item label-width="120px" label="工程承包方" class="item">
									<span class="set_span">
										{{ dataForm.contractCompany }}
									</span>
								</el-form-item>
							</el-col>
							<el-col :span="12">
								<el-form-item label-width="120px" label="工程编号" class="item">
									<el-input class="sp-input" v-model="dataForm.projectSubjectNo" placeholder="请输入"
											v-if="basicVisible">
									</el-input>
									<span class="set_span" v-if="!basicVisible">
										{{ dataForm.projectSubjectNo }}
									</span>
								</el-form-item>
							</el-col>
						</el-row>
						<el-row class="set_border">
							<el-col :span="24">
								<el-form-item label-width="120px" label="项目总监" class="item">
									<el-input class="sp-input1" v-model="dataForm.projectDirector" placeholder="请输入"
											v-if="basicVisible">
									</el-input>
									<span class="set_span" v-if="!basicVisible">
										{{ dataForm.projectDirector }}
									</span>
								</el-form-item>
							</el-col>
						</el-row>
					</el-col>
					<el-col :span="8">
						<i class="el-icon-edit-outline" @click="updateBasicHandle()" v-if="!basicVisible">
							<el-button type="text" size="small" style="font-size: 13px;"
							 :disabled="updateFlg || addFlg">修改</el-button>
						</i>
						<i class="el-icon-finished" @click="updateBasicHandle()" v-if="basicVisible">
							<el-button type="text" size="small" @click="saveBasicHandle()"
							 :disabled="updateFlg || addFlg">保存</el-button>
						</i>
						<i class="el-icon-takeaway-box" v-if="basicVisible" :disabled="updateFlg || addFlg"
							@click="cancelSaveBasicHandle()"> 
							<span style="font-size: 12px;margin-left:2px;">取消</span>
						</i>
					</el-col>
				</el-row>
				<div ref="overview"></div>
			</el-card>
			<el-card style="margin-top: 20px; height: 100%;">
				<div class="elCardTitle">1. 本月概述</div>
				<el-row>
					<draggable v-model="dataForm.projectDesc" @start="onDragStart('010000', '' , '')" @update="onDragEnd">
						<el-col :span="24" v-for="(prodec, index) in dataForm.projectDesc" :key="index">
							<el-col :span="1">
								<i class="el-icon-position"></i>
							</el-col>
							<el-col :span="23" class="set_non">
								<el-col :span="17" v-if="prodec.recNo == '' || (updateFlg && prodec.recNo == updateDescRecNo)">
									<el-input class="desc_input1" type="textarea" autosize v-model="prodec.descContent"></el-input>
								</el-col>
								<el-col :span="17" class="desc_span" v-else>
									{{prodec.descContent}}
								</el-col>
								<el-col :span="4" class="un_col">
									<span class="un_span" v-if="prodec.updUserName != undefined && prodec.updUserName != '' && prodec.updDate != undefined && prodec.updDate != ''">
										{{ prodec.updUserName}} {{prodec.updDate}}
									</span>
								</el-col>
								<!-- 占位 -->
								<!-- <el-col :span="1" > -->
									<el-button v-preventReClick type="text" size="small" class="desc_zw"> . </el-button>
								<!-- </el-col> -->
								<!--新增和修改时按钮操作-->
								<el-col :span="1" v-if="prodec.recNo == '' || (updateFlg && prodec.recNo == updateDescRecNo)">
									<el-button v-preventReClick type="text" size="small" class="desc_btn"
									@click="saveDescHandle(prodec)"> 保存 </el-button>
								</el-col>
								<el-col :span="1" v-if="prodec.recNo == '' || (updateFlg && prodec.recNo == updateDescRecNo)">
									<el-button v-preventReClick type="text" size="small" class="desc_btn"
									@click="cancelDescHandle()"> 取消 </el-button>
								</el-col>
								<!--非新增和修改时按钮操作-->
								<el-col :span="1" v-if="!addFlg && !updateFlg && !basicVisible && prodec.showButton == 1">
									<el-button v-preventReClick type="text" size="small" class="desc_btn"
									@click="updateDescHandle(prodec.recNo)"> <i class="el-icon-edit"></i>修改</el-button>
								</el-col>
								<el-col :span="1" v-if="!addFlg && !updateFlg && !basicVisible && prodec.showButton == 1">
									<el-button v-preventReClick type="text" size="small" class="desc_btn"
									@click="deleteDescHandle(prodec.recNo)"> × 删除</el-button>
								</el-col>
							</el-col>
						</el-col>
					</draggable>
					<el-col :span="24" style="margin-bottom: 20px;margin-top: 10px;">
						<el-button v-preventReClick type="primary" size="mini" icon="el-icon-plus" :disabled="updateFlg || addFlg || basicVisible"
							style="background-color: #00428e;" @click="addRecordDescItem()">追加事项描述</el-button>
					</el-col>

					<el-col :span="24">
						<el-form-item label-width="0">
							<span class="itemLabelspanDetail">1.1 本月生产进度</span>
							<el-radio-group v-model="dataForm.productionSchedule" @change="saveBasicHandle()" :disabled="updateFlg || addFlg || basicVisible">
								<el-radio label="0">正常</el-radio>
								<el-radio label="1">缓慢</el-radio>
							</el-radio-group>
						</el-form-item>
					</el-col>
					<el-col :span="24" style="display:flex;justify-content: flex-start;">
						<el-form-item label-width="0">
							<span class="itemLabelspanDetail1">1.2 本月工程质量状况</span>
							<el-radio-group v-model="dataForm.qualitySituation" @change="saveBasicHandle()" :disabled="updateFlg || addFlg || basicVisible">
								<el-radio label="0">正常</el-radio>
								<el-radio label="1">不正常</el-radio>
							</el-radio-group>
						</el-form-item>
					</el-col>
					<el-col :span="24">
						<el-form-item label-width="0">
							<span class="itemLabelspan" style="margin-right:119px"> 1.3 本月发出监理联系单 共 </span>
							<div class="summaryvalueSpan1" >
								<span class="summaryvalue1"> {{dataForm.contactNum}} </span>
								<span>份</span>
                            </div>
						</el-form-item>
					</el-col>
					<el-col :span="24">
						<el-form-item label-width="0">
							<span class="itemLabelspan" style="margin-right:119px"> 1.4 本月发出监理通知单 共 </span>
							<div class="summaryvalueSpan1" >
								<span class="summaryvalue1"> {{dataForm.noticeNum}} </span>
								<span>份</span>
                            </div>
						</el-form-item>
					</el-col>
					<el-col :span="24">
						<el-form-item label-width="0">
							<span class="itemLabelspan" style="margin-right:123px">1.5 本月发出不一致报告 共</span>
							<div class="summaryvalueSpan1" >
								<span class="summaryvalue1"> {{dataForm.disAccordNum}} </span>
								<span>份</span>
                            </div>
						</el-form-item>
					</el-col>
					<el-col :span="24">
						<el-form-item label-width="0">
							<span class="itemLabelspan" style="margin-right:165px">1.6 本月文件审查 共</span>
							<div class="summaryvalueSpan1" >
								<span class="summaryvalue1"> {{dataForm.fileCheckNum}} </span>
								<span>份</span>
                            </div>
						</el-form-item>
					</el-col>
					<el-col :span="24">
						<el-form-item label-width="0">
							<span class="itemLabelspan" style="margin-right:109px">1.7 除巡检外本月报验项目 共</span>
							<div class="summaryvalueSpan1" >
								<span class="summaryvalue1"> {{dataForm.itemCheckNum}} </span>
								<span>份</span>
                            </div>
						</el-form-item>
					</el-col>
				</el-row>
				<div ref="progress"></div>
			</el-card>
			<el-card style="margin-top: 20px; height: 100%;">
				<div class="elCardTitle">2. 进度情况</div>
				<el-row>
					<el-col :span="20">
						<el-form-item label-width="0">
							<span class="itemLabelspan">2.1 目前工程进度与节点计划比较</span>
						</el-form-item>
					</el-col>
					<el-col :span="20">
						<el-form-item label-width="0">
							<span class="itemLabeltext"> 项目合同工期</span>
						</el-form-item>
					</el-col>
					<el-col :span="24" style="padding-bottom: 10px">
						<el-table class="dataListTable" :data="dataForm.contractList" header-align="center" border
							style="width: 100%;" header-cell-class-name="dataListTHeader" :cell-style="{ padding: '0px' }">
							<el-table-column prop="projectNo" label="船号" align="center" width="250">
							</el-table-column>
							<el-table-column label="交船期" align="center" prop="deliverDate">
								<!-- <template slot-scope="scope">
									<el-input class="selItemInput" v-model="scope.row.deliverDate" placeholder="请输入"
										style="width: 400px"></el-input>
								</template> -->
							</el-table-column>
							<el-table-column label="备  注" align="center" prop="remark" width="300">
								<!-- <template slot-scope="scope">
									<el-input class="selItemInput" v-model="scope.row.remark" placeholder="请输入"
										style="width: 280px"></el-input>
								</template> -->
							</el-table-column>
						</el-table>
					</el-col>
					<el-col :span="20">
						<el-form-item label-width="0">
							<span class="itemLabeltext"> 各船具体节点计划及执行情况</span>
						</el-form-item>
					</el-col>
					<el-table max-height="600" :row-class-name="tableRowClassName" :span-method="objectNodeSpanMethod"
						ref="dataListTable" class="dataListTable" border :data="nodeList"
						header-align="center" header-cell-class-name="dataListTHeader"
						:cell-style="{ padding: '0px' }" style="margin-bottom: 20px;">
						<el-table-column v-for="(v, idx) in headers" :key="idx" v-if="headers != undefined && headers.length > 0 && v.nodeId === '0'"
							:label="v.nodeName" :prop="v.nodeId" align="center" width="130px">
								<template slot-scope="scope">
								<span style="font-weight:bolder">
									{{scope.row.subjectName }}
								</span>
								</template>
						</el-table-column>
						<el-table-column v-for="(v, idx) in headers" :key="idx" v-if="headers != undefined && headers.length > 0 && v.nodeId === '1'"
							:label="v.nodeName" :prop="v.nodeId" align="center" width="60px">
								<template slot-scope="scope">
								<span style="font-weight:bolder">
									{{scope.row.title}}
								</span>
								</template>
						</el-table-column>
						<el-table-column v-for="(v, idx) in headers" :key="idx" v-if="headers != undefined && headers.length > 0 && idx > 1"
							:label="v.nodeName" :prop="v.nodeId" align="center">
								<template slot-scope="scope">
								<span>
									{{scope.row.contentList[idx-2].colValue }}
								</span>
								</template>
						</el-table-column>
					</el-table>
				</el-row>
				<el-row>
					<el-col :span="20">
						<el-form-item label-width="0">
							<span class="itemLabelspan">2.2 目前工程进度与节点计划分析</span></el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-row v-for="(progressPlan, index) in dataForm.progressPlanDesc" :key="index">
						<el-col :span="24" class="set_sub">{{ progressPlan.subjectName }}</el-col>
						<draggable v-model="progressPlan.reportDescInfos" @start="onDragStart('020200', index, '')" @update="onDragEnd">
							<el-col :span="24" v-for="(progressplan, index1) in progressPlan.reportDescInfos" :key="index1">
								<el-col :span="1">
									<i class="el-icon-position"></i>
								</el-col>
								<el-col :span="23" class="set_non" >
									<el-col :span="17" v-if="progressplan.recNo == '' || (updateFlg && progressplan.recNo == updateDescRecNo)">
										<el-input class="desc_input1" type="textarea" autosize v-model="progressplan.descContent"></el-input>
									</el-col>
									<el-col :span="17" class="desc_span" v-else>
										{{progressplan.descContent}}
									</el-col>
									<el-col :span="4" class="un_col">
										<span class="un_span" v-if="progressplan.updUserName != undefined && progressplan.updUserName != '' && progressplan.updDate != undefined && progressplan.updDate != ''">
											{{ progressplan.updUserName}} {{progressplan.updDate}}
										</span>
									</el-col>
									<!-- 占位 -->
									<!-- <el-col :span="1" > -->
										<el-button v-preventReClick type="text" size="small" class="desc_zw"> . </el-button>
									<!-- </el-col> -->
									<!--新增和修改时按钮操作-->
									<el-col :span="1" v-if="progressplan.recNo == '' || (updateFlg && progressplan.recNo == updateDescRecNo)">
										<el-button v-preventReClick type="text" size="small" class="desc_btn"
										@click="saveDescHandle(progressplan)"> 保存 </el-button>
									</el-col>
									<el-col :span="1" v-if="progressplan.recNo == '' || (updateFlg && progressplan.recNo == updateDescRecNo)">
										<el-button v-preventReClick type="text" size="small" class="desc_btn"
										@click="cancelDescHandle()"> 取消 </el-button>
									</el-col>
									<!--非新增和修改时按钮操作-->
									<el-col :span="1" v-if="!addFlg && !updateFlg && !basicVisible && progressplan.showButton == 1">
										<el-button v-preventReClick type="text" size="small" class="desc_btn"
										@click="updateDescHandle(progressplan.recNo)"> <i class="el-icon-edit"></i>修改</el-button>
									</el-col>
									<el-col :span="1" v-if="!addFlg && !updateFlg && !basicVisible && progressplan.showButton == 1">
										<el-button v-preventReClick type="text" size="small" class="desc_btn"
										@click="deleteDescHandle(progressplan.recNo)"> × 删除</el-button>
									</el-col>
								</el-col>
							</el-col>
						</draggable>
						<el-col :span="24" style="margin-top:10px">
							<el-button type="primary" size="mini" icon="el-icon-plus" :disabled="updateFlg || addFlg || basicVisible" style="background-color: #00428e;"
								@click="addProgressPlanDescItem(progressPlan.subjectNo, index)">追加</el-button>
						</el-col>
					</el-row>
				</el-row>
				<el-row>
					<el-col :span="24">
						<el-form-item label-width="0">
							<span class="itemLabelspan">2.3 详细进度情况</span>
						</el-form-item>
					</el-col>
					<el-col :span="24">
						<el-form-item label-width="0">
							<span class="itemLabelspan">2.3.1 船体结构进度情况</span>
						</el-form-item>
					</el-col>
					<el-col :span="24" style="padding-bottom: 10px">
						<el-table class="dataListTable" :data="dataForm.shipProgressList" header-align="center"
							:span-method="objectSpanMethod" style="width: 100%;" border
							header-cell-class-name="dataListTHeader" :cell-style="{ padding: '0px' }">
							<el-table-column prop="projectNo" label="工程编号" align="center">
							</el-table-column>
							<el-table-column label="" align="center">
								<template slot-scope="scope">
									<span v-if="scope.row.progressType === '0'">本 月</span>
									<span v-if="scope.row.progressType === '1'">累 计</span>
									<span v-if="scope.row.progressType === '2'">完工率%</span>
								</template>
							</el-table-column>
							<el-table-column label="上胎架数量" align="center" prop="upperMouldingNum">
							</el-table-column>
							<el-table-column label="验收完成数" align="center" prop="checkFinishNum">
							</el-table-column>
							<el-table-column label="涂装完工数" align="center" prop="paintingFinishNum">
							</el-table-column>
							<el-table-column label="总装分段数" align="center" prop="upShipPartNum">
							</el-table-column>
						</el-table>
					</el-col>
					<el-row v-for="(shipProgress, index) in dataForm.shipProgressDesc" :key="'sp' + index">
						<el-col :span="24" class="set_sub">{{ shipProgress.subjectName }}</el-col>
						<draggable v-model="shipProgress.reportDescInfos" @start="onDragStart('020301', index, '')" @update="onDragEnd">
							<el-col :span="24" v-for="(shipprogress, index1) in shipProgress.reportDescInfos" :key="index1">
								<el-col :span="1">
									<i class="el-icon-position"></i>
								</el-col>
								<el-col :span="23" class="set_non" >
									<el-col :span="17" v-if="shipprogress.recNo == '' || (updateFlg && shipprogress.recNo == updateDescRecNo)">
										<el-input class="desc_input1" type="textarea" autosize v-model="shipprogress.descContent"></el-input>
									</el-col>
									<el-col :span="17" class="desc_span" v-else>
										{{shipprogress.descContent}}
									</el-col>
									<el-col :span="4" class="un_col">
										<span class="un_span" v-if="shipprogress.updUserName != undefined && shipprogress.updUserName != '' && shipprogress.updDate != undefined && shipprogress.updDate != ''">
											{{ shipprogress.updUserName}} {{shipprogress.updDate}}
										</span>
									</el-col>
									<!-- 占位 -->
									<!-- <el-col :span="1" > -->
										<el-button v-preventReClick type="text" size="small" class="desc_zw"> . </el-button>
									<!-- </el-col> -->
									<!--新增和修改时按钮操作-->
									<el-col :span="1" v-if="shipprogress.recNo == '' || (updateFlg && shipprogress.recNo == updateDescRecNo)">
										<el-button v-preventReClick type="text" size="small" class="desc_btn"
										@click="saveDescHandle(shipprogress)"> 保存 </el-button>
									</el-col>
									<el-col :span="1" v-if="shipprogress.recNo == '' || (updateFlg && shipprogress.recNo == updateDescRecNo)">
										<el-button v-preventReClick type="text" size="small" class="desc_btn"
										@click="cancelDescHandle()"> 取消 </el-button>
									</el-col>
									<!--非新增和修改时按钮操作-->
									<el-col :span="1" v-if="!addFlg && !updateFlg && !basicVisible && shipprogress.showButton == 1">
										<el-button v-preventReClick type="text" size="small" class="desc_btn"
										@click="updateDescHandle(shipprogress.recNo)"> <i class="el-icon-edit"></i>修改</el-button>
									</el-col>
									<el-col :span="1" v-if="!addFlg && !updateFlg && !basicVisible && shipprogress.showButton == 1">
										<el-button v-preventReClick type="text" size="small" class="desc_btn"
										@click="deleteDescHandle(shipprogress.recNo)"> × 删除</el-button>
									</el-col>
								</el-col>
							</el-col>
						</draggable>
						<el-col :span="24" style="margin-top:10px">
							<el-button type="primary" size="mini" icon="el-icon-plus" :disabled="updateFlg || addFlg || basicVisible" style="background-color: #00428e;"
								@click="addShipProgressDescItem(shipProgress.subjectNo, index)">追加</el-button>
						</el-col>
					</el-row>
					<el-col :span="24">
						<el-form-item label-width="0">
							<span class="itemLabelspan">2.3.2 涂装工程进度情况</span>
						</el-form-item>
					</el-col>
					<el-row v-for="(paintingProgress, index) in dataForm.paintingProgressList" :key="'pp' + index">
						<el-col :span="24" class="set_sub">{{ paintingProgress.subjectName }}</el-col>
						<draggable v-model="paintingProgress.reportDescInfos" @start="onDragStart('020302', index, '')" @update="onDragEnd">
							<el-col :span="24" v-for="(paintingprogress, index1) in paintingProgress.reportDescInfos" :key="index1">
								<el-col :span="1">
									<i class="el-icon-position"></i>
								</el-col>
								<el-col :span="23" class="set_non" >
									<el-col :span="17" v-if="paintingprogress.recNo == '' || (updateFlg && paintingprogress.recNo == updateDescRecNo)">
										<el-input class="desc_input1" type="textarea" autosize v-model="paintingprogress.descContent"></el-input>
									</el-col>
									<el-col :span="17" class="desc_span" v-else>
										{{paintingprogress.descContent}}
									</el-col>
									<el-col :span="4" class="un_col">
										<span class="un_span" v-if="paintingprogress.updUserName != undefined && paintingprogress.updUserName != '' && paintingprogress.updDate != undefined && paintingprogress.updDate != ''">
											{{ paintingprogress.updUserName}} {{paintingprogress.updDate}}
										</span>
									</el-col>
									<!-- 占位 -->
									<!-- <el-col :span="1" > -->
										<el-button v-preventReClick type="text" size="small" class="desc_zw"> . </el-button>
									<!-- </el-col> -->
									<!--新增和修改时按钮操作-->
									<el-col :span="1" v-if="paintingprogress.recNo == '' || (updateFlg && paintingprogress.recNo == updateDescRecNo)">
										<el-button v-preventReClick type="text" size="small" class="desc_btn"
										@click="saveDescHandle(paintingprogress)"> 保存 </el-button>
									</el-col>
									<el-col :span="1" v-if="paintingprogress.recNo == '' || (updateFlg && paintingprogress.recNo == updateDescRecNo)">
										<el-button v-preventReClick type="text" size="small" class="desc_btn"
										@click="cancelDescHandle()"> 取消 </el-button>
									</el-col>
									<!--非新增和修改时按钮操作-->
									<el-col :span="1" v-if="!addFlg && !updateFlg && !basicVisible && paintingprogress.showButton == 1">
										<el-button v-preventReClick type="text" size="small" class="desc_btn"
										@click="updateDescHandle(paintingprogress.recNo)"> <i class="el-icon-edit"></i>修改</el-button>
									</el-col>
									<el-col :span="1" v-if="!addFlg && !updateFlg && !basicVisible && paintingprogress.showButton == 1">
										<el-button v-preventReClick type="text" size="small" class="desc_btn"
										@click="deleteDescHandle(paintingprogress.recNo)"> × 删除</el-button>
									</el-col>
								</el-col>
							</el-col>
						</draggable>
						<el-col :span="24" style="margin-top:10px">
							<el-button type="primary" size="mini" icon="el-icon-plus" :disabled="updateFlg || addFlg || basicVisible" style="background-color: #00428e;"
								@click="addPaintingProgressItem(paintingProgress.subjectNo, index)">追加</el-button>
						</el-col>
					</el-row>
					<el-col :span="24">
						<el-form-item label-width="0">
							<span class="itemLabelspan">2.3.3 轮机工程进度情况</span>
						</el-form-item>
					</el-col>
					<el-row v-for="(turbineProgress, index) in dataForm.turbineProgressDesc" :key="'tp' + index">
						<el-col :span="24" class="set_sub">{{ turbineProgress.subjectName }}</el-col>
						<draggable v-model="turbineProgress.reportDescInfos" @start="onDragStart('020303', index, '')" @update="onDragEnd">
							<el-col :span="24" v-for="(turbineprogress, index1) in turbineProgress.reportDescInfos" :key="index1">
								<el-col :span="1">
									<i class="el-icon-position"></i>
								</el-col>
								<el-col :span="23" class="set_non" >
									<el-col :span="17" v-if="turbineprogress.recNo == '' || (updateFlg && turbineprogress.recNo == updateDescRecNo)">
										<el-input class="desc_input1" type="textarea" autosize v-model="turbineprogress.descContent"></el-input>
									</el-col>
									<el-col :span="17" class="desc_span" v-else>
										{{turbineprogress.descContent}}
									</el-col>
									<el-col :span="4" class="un_col">
										<span class="un_span" v-if="turbineprogress.updUserName != undefined && turbineprogress.updUserName != '' && turbineprogress.updDate != undefined && turbineprogress.updDate != ''">
											{{ turbineprogress.updUserName}} {{turbineprogress.updDate}}
										</span>
									</el-col>
									<!-- 占位 -->
									<!-- <el-col :span="1" > -->
										<el-button v-preventReClick type="text" size="small" class="desc_zw"> . </el-button>
									<!-- </el-col> -->
									<!--新增和修改时按钮操作-->
									<el-col :span="1" v-if="turbineprogress.recNo == '' || (updateFlg && turbineprogress.recNo == updateDescRecNo)">
										<el-button v-preventReClick type="text" size="small" class="desc_btn"
										@click="saveDescHandle(turbineprogress)"> 保存 </el-button>
									</el-col>
									<el-col :span="1" v-if="turbineprogress.recNo == '' || (updateFlg && turbineprogress.recNo == updateDescRecNo)">
										<el-button v-preventReClick type="text" size="small" class="desc_btn"
										@click="cancelDescHandle()"> 取消 </el-button>
									</el-col>
									<!--非新增和修改时按钮操作-->
									<el-col :span="1" v-if="!addFlg && !updateFlg && !basicVisible && turbineprogress.showButton == 1">
										<el-button v-preventReClick type="text" size="small" class="desc_btn"
										@click="updateDescHandle(turbineprogress.recNo)"> <i class="el-icon-edit"></i>修改</el-button>
									</el-col>
									<el-col :span="1" v-if="!addFlg && !updateFlg && !basicVisible && turbineprogress.showButton == 1">
										<el-button v-preventReClick type="text" size="small" class="desc_btn"
										@click="deleteDescHandle(turbineprogress.recNo)"> × 删除</el-button>
									</el-col>
								</el-col>
							</el-col>
						</draggable>
						<el-col :span="24" style="margin-top:10px">
							<el-button type="primary" size="mini" icon="el-icon-plus" :disabled="updateFlg || addFlg || basicVisible" style="background-color: #00428e;"
								@click="addTurbineProgressDescItem(turbineProgress.subjectNo, index)">追加</el-button>
						</el-col>
					</el-row>
					<el-col :span="24">
						<el-form-item label-width="0">
							<span class="itemLabelspan">2.3.4 电气工程进度情况</span>
						</el-form-item>
					</el-col>
					<el-row v-for="(electricalProgress, index) in dataForm.electricalProgressDesc" :key="'ep' + index">
						<el-col :span="24" class="set_sub">{{ electricalProgress.subjectName }}</el-col>
						<draggable v-model="electricalProgress.reportDescInfos" @start="onDragStart('020304', index, '')" @update="onDragEnd">
							<el-col :span="24" v-for="(electricalprogress, index1) in electricalProgress.reportDescInfos" :key="index1">
								<el-col :span="1">
									<i class="el-icon-position"></i>
								</el-col>
								<el-col :span="23" class="set_non" >
									<el-col :span="17" v-if="electricalprogress.recNo == '' || (updateFlg && electricalprogress.recNo == updateDescRecNo)">
										<el-input class="desc_input1" type="textarea" autosize v-model="electricalprogress.descContent"></el-input>
									</el-col>
									<el-col :span="17" class="desc_span" v-else>
										{{electricalprogress.descContent}}
									</el-col>
									<el-col :span="4" class="un_col">
										<span class="un_span" v-if="electricalprogress.updUserName != undefined && electricalprogress.updUserName != '' && electricalprogress.updDate != undefined && electricalprogress.updDate != ''">
											{{ electricalprogress.updUserName}} {{electricalprogress.updDate}}
										</span>
									</el-col>
									<!-- 占位 -->
									<!-- <el-col :span="1" > -->
										<el-button v-preventReClick type="text" size="small" class="desc_zw"> . </el-button>
									<!-- </el-col> -->
									<!--新增和修改时按钮操作-->
									<el-col :span="1" v-if="electricalprogress.recNo == '' || (updateFlg && electricalprogress.recNo == updateDescRecNo)">
										<el-button v-preventReClick type="text" size="small" class="desc_btn"
										@click="saveDescHandle(electricalprogress)"> 保存 </el-button>
									</el-col>
									<el-col :span="1" v-if="electricalprogress.recNo == '' || (updateFlg && electricalprogress.recNo == updateDescRecNo)">
										<el-button v-preventReClick type="text" size="small" class="desc_btn"
										@click="cancelDescHandle()"> 取消 </el-button>
									</el-col>
									<!--非新增和修改时按钮操作-->
									<el-col :span="1" v-if="!addFlg && !updateFlg && !basicVisible && electricalprogress.showButton == 1">
										<el-button v-preventReClick type="text" size="small" class="desc_btn"
										@click="updateDescHandle(electricalprogress.recNo)"> <i class="el-icon-edit"></i>修改</el-button>
									</el-col>
									<el-col :span="1" v-if="!addFlg && !updateFlg && !basicVisible && electricalprogress.showButton == 1">
										<el-button v-preventReClick type="text" size="small" class="desc_btn"
										@click="deleteDescHandle(electricalprogress.recNo)"> × 删除</el-button>
									</el-col>
								</el-col>
							</el-col>
						</draggable>
						<el-col :span="24" style="margin-top:10px">
							<el-button type="primary" size="mini" icon="el-icon-plus" :disabled="updateFlg || addFlg || basicVisible" style="background-color: #00428e;"
								@click="addElectricalProgressDescItem(electricalProgress.subjectNo, index)">追加</el-button>
						</el-col>
					</el-row>
				</el-row>
				<el-row>
					<el-col :span="24">
						<el-form-item label-width="0">
							<span class="itemLabelspan">2.4 月末进度照片</span>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row v-for="(progressPhotoRecord, idxs) in dataForm.progressPhotoRecords" :key="idxs">
					<el-col :span="24" class="set_sub">{{ progressPhotoRecord.projectNo }}</el-col>
					<el-button v-preventReClick  type="primary" size="small" :disabled="updateFlg || addFlg || basicVisible" 
						style="margin-bottom: 10px; background-color: #00428e;" @click="handleProgressPhotoSelect(progressPhotoRecord, idxs)">选择照片</el-button>
					<el-col :span="24">
						<div class="card-container">
							<div class="card-row" v-for="(row, rowIndex) in cardRows(progressPhotoRecord.photoList)"
								:key="rowIndex">
								<el-row>
									<div class="card" v-for="(item, colIndex) in row" :key="item.recNo"
										@dragstart="dragStart('progress', idxs, rowIndex, colIndex)" @dragover.prevent
										@drop="drop('progress', idxs, rowIndex, colIndex)" @dragend="dragEnd()"
										draggable="true">
										<el-col :span="4">
											<el-card :body-style="{ padding: '0px' }"
												style="margin-bottom: 10px;margin-right: 5px;height: 285px;">
												<el-image :src="item.photoPathUri" fit="scale-down"
													class="image"></el-image>
												<div style="padding: 2px 10px;">
													<div class="t-column-ellipsis">
														<span>{{ item.photoContent }}</span>
													</div>
													<div class="bottom clearfix">
														<el-button type="text" class="button" :disabled="updateFlg || addFlg || basicVisible"
															@click="delImg(item.recNo)">删除</el-button>
														<el-button type="text" class="button" :disabled="updateFlg || addFlg || basicVisible"
															style="margin-right: 10px;"
															@click="updateImgContent(item.recNo, item.photoContent)">修改</el-button>
													</div>
												</div>
											</el-card>
										</el-col>
									</div>
								</el-row>
							</div>
						</div>
					</el-col>
				</el-row>
				<el-row>
					<div v-if="!dataForm.progressPhotoRecords || dataForm.progressPhotoRecords.length === 0">
						<el-col :span="24">
							<span style="color: #e7b243;;font-size: 14px;">暂无进度照片</span>
						</el-col>
					</div>
				</el-row>
				<div ref="quality"></div>
			</el-card>
			<el-card style="margin-top: 20px; height: 100%;">
				<div class="elCardTitle">3. 质量情况</div>
				<el-row>
					<el-col :span="24">
						<el-form-item label-width="0">
							<span class="itemLabelspan">3.1 月度停止点检验汇总</span>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="24" style="padding-bottom: 10px">
						<el-table class="dataListTable" :data="dataForm.testRecordList" header-align="center" border
							style="width: 100%;" header-cell-class-name="dataListTHeader" :cell-style="{ padding: '0px' }">
							<el-table-column prop="projectNo" label="工程编号" align="center">
							</el-table-column>
							<el-table-column prop="majorName" label="专 业" align="center">
							</el-table-column>
							<el-table-column prop="testConclusionCount" label="接受数" align="center">
							</el-table-column>
							<el-table-column prop="testConclusionWhereCount" label="有条件接受" align="center">
							</el-table-column>
							<el-table-column prop="recheckAfterCorrectionCount" label="修正后复检" align="center">
							</el-table-column>
							<el-table-column prop="testConclusionRefuseCount" label="不接受交验" align="center">
							</el-table-column>
							<el-table-column prop="testConclusionRate" label="一次合格率" align="center">
								<template slot-scope="scope">
									{{ scope.row.testConclusionRate }}%
								</template>
							</el-table-column>
						</el-table>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="24">
						<el-form-item label-width="0">
							<span class="itemLabelspan">3.2 巡检意见汇总</span>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="24" style="padding-bottom: 10px">
						<el-table class="dataListTable" :data="dataForm.patrolRectificationList" header-align="center"
							border style="width: 100%;" header-cell-class-name="dataListTHeader"
							:cell-style="{ padding: '0px' }">
							<el-table-column label="工程编号" align="center">
								<template slot-scope="scope">
									{{ scope.row.projectNo }}
								</template>
							</el-table-column>
							<el-table-column label="专 业" align="center">
								<template slot-scope="scope">
									{{ scope.row.majorName }}
								</template>
							</el-table-column>
							<el-table-column prop="applyNum" label="本月提出整改数" align="center">
							</el-table-column>
							<el-table-column prop="alreadyModifyNum" label="本月已整改数" align="center">
							</el-table-column>
							<el-table-column prop="notFinishNum" label="截止本月未整改数" align="center">
							</el-table-column>
							<el-table-column prop="finishRate" label="整改完成率" align="center">
								<template slot-scope="scope">
									{{ scope.row.finishRate }}%
								</template>
							</el-table-column>
						</el-table>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="24">
						<el-form-item label-width="0">
							<span class="itemLabelspan">3.3 监理联系单汇总</span>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="24">
						<el-form-item label-width="0">
							<span class="itemLabeltext">截止本月末共下发</span>
							<span class="summaryvalue2"> {{dataForm.contactTotalNum}} </span>
							<!-- <el-input class="selItemInput" v-model="dataForm.contactTotalNum" placeholder="请输入"></el-input> -->
							份监理联系单，已整改完成
							<!-- <el-input class="selItemInput" v-model="dataForm.contactFinishNum" placeholder="请输入"></el-input> -->
							<span class="summaryvalue2"> {{dataForm.contactFinishNum}} </span>
							份
						</el-form-item>
					</el-col>
				</el-row>
				<el-row v-for="(contactItemRecord, index) in dataForm.contactItemRecordList" :key="index">
					<el-col class="set_sub">{{ contactItemRecord.projectNo }}</el-col>
					<el-col :span="24" style="margin-bottom: 10px;margin-top:10px">
						<el-table class="dataListTable" :data="contactItemRecord.itemRecordList" header-align="center" border
							style="width: 100%;" header-cell-class-name="dataListTHeader" :cell-style="{ padding: '0px' }">
							<el-table-column prop="noticeNo" label="监理联系单编号" min-width="200" align="left">
							</el-table-column>
							<el-table-column prop="primaryContent" label="主要内容" min-width="350" align="left"
								show-overflow-tooltip>
							</el-table-column>
							<el-table-column prop="sendTime" label="下发日期" width="120" align="center">
							</el-table-column>
							<el-table-column prop="closeTime" label="封闭日期" width="120" align="center">
							</el-table-column>
							<el-table-column label="备 注" prop="remark" width="200" align="center" show-overflow-tooltip>
							</el-table-column>
						</el-table>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="24">
						<el-form-item label-width="0">
							<span class="itemLabelspan">3.4 监理通知单汇总</span>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="24">
						<el-form-item label-width="0">
							<span class="itemLabeltext">截止本月末共下发</span>
							<span class="summaryvalue2"> {{dataForm.noticeTotalNum}} </span>
							<!-- <el-input class="selItemInput" v-model="dataForm.contactTotalNum" placeholder="请输入"></el-input> -->
							份监理通知单，已整改完成
							<!-- <el-input class="selItemInput" v-model="dataForm.contactFinishNum" placeholder="请输入"></el-input> -->
							<span class="summaryvalue2"> {{dataForm.noticeFinishNum}} </span>
							份
						</el-form-item>
					</el-col>
				</el-row>
				<el-row v-for="(noticeItemRecord, index) in dataForm.noticeItemRecordList" :key="index">
					<el-col class="set_sub">{{ noticeItemRecord.projectNo }}</el-col>
					<el-col :span="24" style="margin-bottom: 10px;margin-top:10px">
						<el-table class="dataListTable" :data="noticeItemRecord.itemRecordList" header-align="center" border
							style="width: 100%;" header-cell-class-name="dataListTHeader" :cell-style="{ padding: '0px' }">
							<el-table-column prop="noticeNo" label="监理通知单编号" min-width="200" align="left">
							</el-table-column>
							<el-table-column prop="primaryContent" label="主要内容" min-width="350" align="left"
								show-overflow-tooltip>
							</el-table-column>
							<el-table-column prop="sendTime" label="下发日期" width="120" align="center">
							</el-table-column>
							<el-table-column prop="closeTime" label="封闭日期" width="120" align="center">
							</el-table-column>
							<el-table-column label="备 注" prop="remark" width="200" align="center" show-overflow-tooltip>
							</el-table-column>
						</el-table>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="24">
						<el-form-item label-width="0">
							<span class="itemLabelspan">3.5 不一致报告汇总</span>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<draggable v-model="dataForm.disAccordDesc" @start="onDragStart('030400', '' , '')" @update="onDragEnd">
						<el-col :span="24" v-for="(disAccord, index) in dataForm.disAccordDesc" :key="index">
							<el-col :span="1">
								<i class="el-icon-position"></i>
							</el-col>
							<el-col :span="23" class="set_non">
								<el-col :span="17" v-if="disAccord.recNo == '' || (updateFlg && disAccord.recNo == updateDescRecNo)">
									<el-input class="desc_input1" type="textarea" autosize v-model="disAccord.descContent"></el-input>
								</el-col>
								<el-col :span="17" class="desc_span" v-else>
									{{disAccord.descContent}}
								</el-col>
								<el-col :span="4" class="un_col">
									<span class="un_span" v-if="disAccord.updUserName != undefined && disAccord.updUserName != '' && disAccord.updDate != undefined && disAccord.updDate != ''">
										{{ disAccord.updUserName}} {{disAccord.updDate}}
									</span>
								</el-col>
								<!-- 占位 -->
								<!-- <el-col :span="1" > -->
									<el-button v-preventReClick type="text" size="small" class="desc_zw"> . </el-button>
								<!-- </el-col> -->
								<!--新增和修改时按钮操作-->
								<el-col :span="1" v-if="disAccord.recNo == '' || (updateFlg && disAccord.recNo == updateDescRecNo)">
									<el-button v-preventReClick type="text" size="small" class="desc_btn"
									@click="saveDescHandle(disAccord)"> 保存 </el-button>
								</el-col>
								<el-col :span="1" v-if="disAccord.recNo == '' || (updateFlg && disAccord.recNo == updateDescRecNo)">
									<el-button v-preventReClick type="text" size="small" class="desc_btn"
									@click="cancelDescHandle()"> 取消 </el-button>
								</el-col>
								<!--非新增和修改时按钮操作-->
								<el-col :span="1" v-if="!addFlg && !updateFlg && !basicVisible && disAccord.showButton == 1">
									<el-button v-preventReClick type="text" size="small" class="desc_btn"
									@click="updateDescHandle(disAccord.recNo)"> <i class="el-icon-edit"></i>修改</el-button>
								</el-col>
								<el-col :span="1" v-if="!addFlg && !updateFlg && !basicVisible && disAccord.showButton == 1">
									<el-button v-preventReClick type="text" size="small" class="desc_btn"
									@click="deleteDescHandle(disAccord.recNo)"> × 删除</el-button>
								</el-col>
							</el-col>
						</el-col>
					</draggable>
					<el-col :span="24" style="margin-bottom: 20px;margin-top: 10px;">
						<el-button v-preventReClick type="primary" size="mini" icon="el-icon-plus" :disabled="updateFlg || addFlg || basicVisible"
							style="background-color: #00428e;" @click="addDisAccordDescItem()">追加不一致报告汇总</el-button>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="24">
						<el-form-item label-width="0">
							<span class="itemLabelspan">3.6 本月施工质量分析</span>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="24" v-for="(constructionQuality, index) in dataForm.constructionQualityDesc" :key="index">
						<el-col :span="24" class="set_sub">{{ constructionQuality.subjectName }}</el-col>
						<el-row v-for="(conQuality, index1) in constructionQuality.conQualityList" :key="index1">
							<el-row>
								<el-col class="conp">{{ conQuality.majorName }}</el-col>
							</el-row>
							<draggable v-model="conQuality.qualityDescList" @start="onDragStart('030500', index, index1)" @update="onDragEnd">
								<el-row v-for="(qualityDesc, index2) in conQuality.qualityDescList" :key="index2">
									<el-col :span="1">
										<i class="el-icon-position"></i>
									</el-col>
									<el-col :span="23" class="set_non">
										<el-col :span="17" v-if="qualityDesc.recNo == '' || (updateFlg && qualityDesc.recNo == updateDescRecNo)">
											<el-input class="desc_input1" type="textarea" autosize v-model="qualityDesc.descContent"></el-input>
										</el-col>
										<el-col :span="17" class="desc_span" v-else>
											{{qualityDesc.descContent}}
										</el-col>
										<el-col :span="4" class="un_col">
											<span class="un_span" v-if="qualityDesc.updUserName != undefined && qualityDesc.updUserName != '' && qualityDesc.updDate != undefined && qualityDesc.updDate != ''">
												{{ qualityDesc.updUserName}} {{qualityDesc.updDate}}
											</span>
										</el-col>
										<!-- 占位 -->
										<!-- <el-col :span="1" > -->
											<el-button v-preventReClick type="text" size="small" class="desc_zw"> . </el-button>
										<!-- </el-col> -->
										<!--新增和修改时按钮操作-->
										<el-col :span="1" v-if="qualityDesc.recNo == '' || (updateFlg && qualityDesc.recNo == updateDescRecNo)">
											<el-button v-preventReClick type="text" size="small" class="desc_btn"
											@click="saveDescHandle(qualityDesc)"> 保存 </el-button>
										</el-col>
										<el-col :span="1" v-if="qualityDesc.recNo == '' || (updateFlg && qualityDesc.recNo == updateDescRecNo)">
											<el-button v-preventReClick type="text" size="small" class="desc_btn"
											@click="cancelDescHandle()"> 取消 </el-button>
										</el-col>
										<!--非新增和修改时按钮操作-->
										<el-col :span="1" v-if="!addFlg && !updateFlg && !basicVisible && qualityDesc.showButton == 1">
											<el-button v-preventReClick type="text" size="small" class="desc_btn"
											@click="updateDescHandle(qualityDesc.recNo)"> <i class="el-icon-edit"></i>修改</el-button>
										</el-col>
										<el-col :span="1" v-if="!addFlg && !updateFlg && !basicVisible && qualityDesc.showButton == 1">
											<el-button v-preventReClick type="text" size="small" class="desc_btn"
											@click="deleteDescHandle(qualityDesc.recNo)"> × 删除</el-button>
										</el-col>
									</el-col>
								</el-row>
							</draggable>
							<el-col :span="24" style="margin-top:10px">
								<el-button v-preventReClick type="primary" size="mini" icon="el-icon-plus" :disabled="updateFlg || addFlg || basicVisible" 
								style="background-color: #00428e;" @click="addConstructionQualityDescItem(constructionQuality.subjectNo, conQuality.major, index, index1)">追加</el-button>
							</el-col>
						</el-row>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="24">
						<el-form-item label-width="0">
							<span class="itemLabelspan">3.7 报告文件审查情况</span>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="24">
						<el-table class="dataListTable" :data="dataForm.fileCheckRecords" header-align="center" border
							style="width: 100%;" header-cell-class-name="dataListTHeader" :cell-style="{ padding: '0px' }">
							<el-table-column type="index" label="序号" align="center" width="150">
							</el-table-column>
							<el-table-column prop="fileName" label="文件名称" align="center" min-width="250">
							</el-table-column>
							<el-table-column prop="examineStatusName" label="审查状态" align="center" min-width="150">
							</el-table-column>
							<el-table-column prop="examineSuggestion" label="审查意见" show-overflow-tooltip align="center"
								min-width="150">
							</el-table-column>
						</el-table>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="24">
						<el-form-item label-width="0">
							<span class="itemLabelspan">3.8 设计修改、图纸审查（终审）落实情况</span>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<draggable v-model="dataForm.designEditDesc" @start="onDragStart('030700','' , '')" @update="onDragEnd">
						<el-col :span="24" v-for="(designedit, index) in dataForm.designEditDesc" :key="index">
							<el-col :span="1">
								<i class="el-icon-position"></i>
							</el-col>
							<el-col :span="23" class="set_non">
								<el-col :span="17" v-if="designedit.recNo == '' || (updateFlg && designedit.recNo == updateDescRecNo)">
									<el-input class="desc_input1" type="textarea" autosize v-model="designedit.descContent"></el-input>
								</el-col>
								<el-col :span="17" class="desc_span" v-else>
									{{designedit.descContent}}
								</el-col>
								<el-col :span="4" class="un_col">
									<span class="un_span" v-if="designedit.updUserName != undefined && designedit.updUserName != '' && designedit.updDate != undefined && designedit.updDate != ''">
										{{ designedit.updUserName}} {{designedit.updDate}}
									</span>
								</el-col>
								<!-- 占位 -->
								<!-- <el-col :span="1" > -->
									<el-button v-preventReClick type="text" size="small" class="desc_zw"> . </el-button>
								<!-- </el-col> -->
								<!--新增和修改时按钮操作-->
								<el-col :span="1" v-if="designedit.recNo == '' || (updateFlg && designedit.recNo == updateDescRecNo)">
									<el-button v-preventReClick type="text" size="small" class="desc_btn"
									@click="saveDescHandle(designedit)"> 保存 </el-button>
								</el-col>
								<el-col :span="1" v-if="designedit.recNo == '' || (updateFlg && designedit.recNo == updateDescRecNo)">
									<el-button v-preventReClick type="text" size="small" class="desc_btn"
									@click="cancelDescHandle()"> 取消 </el-button>
								</el-col>
								<!--非新增和修改时按钮操作-->
								<el-col :span="1" v-if="!addFlg && !updateFlg && !basicVisible && designedit.showButton == 1">
									<el-button v-preventReClick type="text" size="small" class="desc_btn"
									@click="updateDescHandle(designedit.recNo)"> <i class="el-icon-edit"></i>修改</el-button>
								</el-col>
								<el-col :span="1" v-if="!addFlg && !updateFlg && !basicVisible && designedit.showButton == 1">
									<el-button v-preventReClick type="text" size="small" class="desc_btn"
									@click="deleteDescHandle(designedit.recNo)"> × 删除</el-button>
								</el-col>
							</el-col>
						</el-col>
					</draggable>
					<el-col :span="24" style="margin-bottom: 20px;margin-top: 10px;">
						<el-button v-preventReClick type="primary" size="mini" icon="el-icon-plus" :disabled="updateFlg || addFlg || basicVisible"
							style="background-color: #00428e;" @click="addDesignEditDescItem()">追加设计修改、图纸审查（终审）落实情况</el-button>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="24">
						<el-form-item label-width="0">
							<span class="itemLabelspan">3.9 质量照片</span>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row v-for="(qualityPhotoRecord, idxs) in dataForm.qualityPhotoRecords">
					<el-col :span="24" class="set_sub">{{ qualityPhotoRecord.projectNo }}</el-col>
					<el-button v-preventReClick  type="primary" size="small" :disabled="updateFlg || addFlg || basicVisible"
						 style="margin-bottom: 10px;background-color: #00428e;"	@click="handleQualityPhotoSelect(qualityPhotoRecord, idxs)">选择照片</el-button>
					<el-col :span="24">
						<div class="card-container">
							<div class="card-row" v-for="(row, rowIndex) in cardRows(qualityPhotoRecord.photoList)"
								:key="rowIndex">
								<el-row>
									<div class="card" v-for="(item, colIndex) in row" :key="item.recNo"
										@dragstart="dragStart('quality', idxs, rowIndex, colIndex)" @dragover.prevent
										@drop="drop('quality', idxs, rowIndex, colIndex)" @dragend="dragEnd()"
										draggable="true">
										<el-col :span="4">
											<el-card :body-style="{ padding: '0px' }"
												style="margin-bottom: 10px;margin-right: 5px;height: 285px;">
												<el-image :src="item.photoPathUri" fit="scale-down"
													class="image"></el-image>
												<div style="padding: 2px 10px;">
													<div class="t-column-ellipsis">
														<span>{{ item.photoContent }}</span>
													</div>
													<div class="bottom clearfix">
														<el-button type="text" class="button" :disabled="updateFlg || addFlg || basicVisible"
															@click="delImg(item.recNo)">删除</el-button>
														<el-button type="text" class="button" :disabled="updateFlg || addFlg || basicVisible"
															style="margin-right: 10px;"
															@click="updateImgContent(item.recNo, item.photoContent)">修改</el-button>
													</div>
												</div>
											</el-card>
										</el-col>
									</div>
								</el-row>
							</div>
						</div>
					</el-col>
				</el-row>
				<div v-if="!dataForm.qualityPhotoRecords || dataForm.qualityPhotoRecords.length === 0">
					<span style="color: #e7b243;font-size: 14px;">暂无质量照片</span>
				</div>
				<div ref="meet"></div>
			</el-card>
			<el-card style="margin-top: 20px; height: 100%;">
				<div class="elCardTitle">4. 会议情况</div>
				<el-row v-for="(desc, idx) in meetDescList">
					<el-col>
						<el-row>
							<el-col :span="20" style="margin-bottom: 10px;">
								{{ meetDescList[idx].descContent }}
							</el-col>
							<el-col :span="4">
								<el-button v-preventReClick type="text" class="button"
									@click="delMeetDesc(idx)">删除</el-button>
							</el-col>
						</el-row>
						<el-row>
							<el-col :span="24">
								<div class="card-container">
									<div class="card-row"
										v-for="(row, rowIndex) in cardRows(meetDescList[idx].photoRecords)" :key="rowIndex">
										<el-row>
											<div class="card" v-for="(item, colIndex) in row" :key="item.id"
												:style="{ order: item.order }"
												@dragstart="dragStart('meeting', idx, rowIndex, colIndex)" @dragover.prevent
												@drop="drop('meeting', idx, rowIndex, colIndex)" @dragend="dragEnd"
												draggable="true">
												<el-col :span="4">
													<el-card :body-style="{ padding: '0px' }"
														style="margin-bottom: 10px;margin-right: 5px;height: 285px;">
														<el-image :src="item.photoFilePathUrl" fit="scale-down"
															class="image"></el-image>
														<div style="padding: 2px 10px;">
															<div class="t-column-ellipsis">
																<span>{{ item.photoContent }}</span>
															</div>
														</div>
													</el-card>
												</el-col>
											</div>
										</el-row>
									</div>
								</div>
							</el-col>
						</el-row>
					</el-col>
				</el-row>
				<div ref="hse"></div>
			</el-card>
			<el-card style="margin-top: 20px; height: 100%;">
				<div class="elCardTitle">5. HSE情况</div>
				<el-row>
					<draggable v-model="dataForm.hseInfoDesc" @start="onDragStart('050000','' , '')" @update="onDragEnd">
						<el-col :span="24" v-for="(hseinfo, index) in dataForm.hseInfoDesc" :key="index">
							<el-col :span="1">
								<i class="el-icon-position"></i>
							</el-col>
							<el-col :span="23" class="set_non">
								<el-col :span="17" v-if="hseinfo.recNo == '' || (updateFlg && hseinfo.recNo == updateDescRecNo)">
									<el-input class="desc_input1" type="textarea" autosize v-model="hseinfo.descContent"></el-input>
								</el-col>
								<el-col :span="17" class="desc_span" v-else>
									{{hseinfo.descContent}}
								</el-col>
								<el-col :span="4" class="un_col">
									<span class="un_span" v-if="hseinfo.updUserName != undefined && hseinfo.updUserName != '' && hseinfo.updDate != undefined && hseinfo.updDate != ''">
										{{ hseinfo.updUserName}} {{hseinfo.updDate}}
									</span>
								</el-col>
								<!-- 占位 -->
								<!-- <el-col :span="1" > -->
									<el-button v-preventReClick type="text" size="small" class="desc_zw"> . </el-button>
								<!-- </el-col> -->
								<!--新增和修改时按钮操作-->
								<el-col :span="1" v-if="hseinfo.recNo == '' || (updateFlg && hseinfo.recNo == updateDescRecNo)">
									<el-button v-preventReClick type="text" size="small" class="desc_btn"
									@click="saveDescHandle(hseinfo)"> 保存 </el-button>
								</el-col>
								<el-col :span="1" v-if="hseinfo.recNo == '' || (updateFlg && hseinfo.recNo == updateDescRecNo)">
									<el-button v-preventReClick type="text" size="small" class="desc_btn"
									@click="cancelDescHandle()"> 取消 </el-button>
								</el-col>
								<!--非新增和修改时按钮操作-->
								<el-col :span="1" v-if="!addFlg && !updateFlg && !basicVisible && hseinfo.showButton == 1">
									<el-button v-preventReClick type="text" size="small" class="desc_btn"
									@click="updateDescHandle(hseinfo.recNo)"> <i class="el-icon-edit"></i>修改</el-button>
								</el-col>
								<el-col :span="1" v-if="!addFlg && !updateFlg && !basicVisible && hseinfo.showButton == 1">
									<el-button v-preventReClick type="text" size="small" class="desc_btn"
									@click="deleteDescHandle(hseinfo.recNo)"> × 删除</el-button>
								</el-col>
							</el-col>
						</el-col>
					</draggable>
					<el-col :span="24" style="margin-bottom: 20px;margin-top: 10px;">
						<el-button v-preventReClick type="primary" size="mini" icon="el-icon-plus" :disabled="updateFlg || addFlg || basicVisible"
							style="background-color: #00428e;" @click="addHseInfoDescItem()">追加HSE情况</el-button>
					</el-col>
				</el-row>
				<div ref="other"></div>
			</el-card>
			<el-card style="margin-top: 20px; height: 100%;">
				<div class="elCardTitle">6. 其他需要说明的问题</div>
				<el-row>
					<draggable v-model="dataForm.otherDesc" @start="onDragStart('060000','' , '')" @update="onDragEnd">
						<el-col :span="24" v-for="(other, index) in dataForm.otherDesc" :key="index">
							<el-col :span="1">
								<i class="el-icon-position"></i>
							</el-col>
							<el-col :span="23" class="set_non">
								<el-col :span="17" v-if="other.recNo == '' || (updateFlg && other.recNo == updateDescRecNo)">
									<el-input class="desc_input1" type="textarea" autosize v-model="other.descContent"></el-input>
								</el-col>
								<el-col :span="17" class="desc_span" v-else>
									{{other.descContent}}
								</el-col>
								<el-col :span="4" class="un_col">
									<span class="un_span" v-if="other.updUserName != undefined && other.updUserName != '' && other.updDate != undefined && other.updDate != ''">
										{{ other.updUserName}} {{other.updDate}}
									</span>
								</el-col>
								<!-- 占位 -->
								<!-- <el-col :span="1" > -->
									<el-button v-preventReClick type="text" size="small" class="desc_zw"> . </el-button>
								<!-- </el-col> -->
								<!--新增和修改时按钮操作-->
								<el-col :span="1" v-if="other.recNo == '' || (updateFlg && other.recNo == updateDescRecNo)">
									<el-button v-preventReClick type="text" size="small" class="desc_btn"
									@click="saveDescHandle(other)"> 保存 </el-button>
								</el-col>
								<el-col :span="1" v-if="other.recNo == '' || (updateFlg && other.recNo == updateDescRecNo)">
									<el-button v-preventReClick type="text" size="small" class="desc_btn"
									@click="cancelDescHandle()"> 取消 </el-button>
								</el-col>
								<!--非新增和修改时按钮操作-->
								<el-col :span="1" v-if="!addFlg && !updateFlg && !basicVisible && other.showButton == 1">
									<el-button v-preventReClick type="text" size="small" class="desc_btn"
									@click="updateDescHandle(other.recNo)"> <i class="el-icon-edit"></i>修改</el-button>
								</el-col>
								<el-col :span="1" v-if="!addFlg && !updateFlg && !basicVisible && other.showButton == 1">
									<el-button v-preventReClick type="text" size="small" class="desc_btn"
									@click="deleteDescHandle(other.recNo)"> × 删除</el-button>
								</el-col>
							</el-col>
						</el-col>
					</draggable>
					<el-col :span="24" style="margin-bottom: 20px;margin-top: 10px;">
						<el-button v-preventReClick type="primary" size="mini" icon="el-icon-plus" :disabled="updateFlg || addFlg || basicVisible"
							style="background-color: #00428e;" @click="addOtherDescItem()">追加其他需要说明的问题</el-button>
					</el-col>
				</el-row>
				<div ref="enclosure"></div>
			</el-card>
			<el-card style="margin-top: 20px; height: 100%;">
				<div class="elCardTitle">7. 随月报发送的附件</div>
				<el-row>
					<el-col :span="24"><el-button v-preventReClick class="defaultPlainBtn" plain icon="el-icon-upload"
							@click="editHandle()">添加附件</el-button></el-col>
				</el-row>
				<el-row>
					<el-col :span="24">
						<!--					<el-input type="textarea" rows="3" v-model="dataForm.enclosureDesc" placeholder="请填写"></el-input>-->
						<el-table ref="singleTable" class="dataListTable" :data="enclosureList" header-align="center" border
							style="width: 100%;" empty-text="保存后请通过附件上传功能上传附件" header-cell-class-name="dataListTHeader"
							row-key="recNo" :cell-style="{ padding: '0px' }">
							<el-table-column type="index" label="序号" align="center" width="60">
							</el-table-column>
							<el-table-column prop="fileName" label="文件名称" align="left" min-width="250">
							</el-table-column>
							<el-table-column prop="remark" label="备注" align="center" min-width="150">
							</el-table-column>
							<el-table-column label="操作" prop="remark" align="center" width="300">
								<template slot-scope="scope">
									<el-button v-preventReClick type="danger" size="small"
										@click="downloadHandle(scope.row.filePathUri, scope.row.fileName)"
										v-if="scope.row.filePathUri">下载</el-button>
									<el-button v-preventReClick type="warning" size="small"
										@click="editHandle(scope.row.recNo)">修改</el-button>
									<el-button v-preventReClick type="danger" size="small"
										@click="deleteHandle(scope.row.recNo)">删除</el-button>
								</template>
							</el-table-column>
						</el-table>
					</el-col>
				</el-row>
			</el-card>
		</el-form>
		<el-row style="height:68px">
		</el-row>
		<!-- <el-row class="formBtnGroup" style="margin-top: 40px;">
			<div class="set_bg">
				<el-button v-preventReClick class="btn1" type="primary" size="small"
					@click="dataFormSubmit()">保存</el-button>
				<el-button v-preventReClick class="rebtn1" size="small" plain @click="goBack()">取消</el-button>
			</div>
		</el-row> -->
		<el-row>
			<div class="set_btn" v-if="menuId !== 'basic'" @click="jump_to_basic" style="bottom: 147px;"></div>
			<div class="set_btn_border" v-if="menuId === 'basic'" @click="jump_to_basic" style="bottom: 147px;"></div>
			<div class="set_menu" style="bottom: 145px;" @click="jump_to_basic">基本信息</div>
		</el-row>
		<el-row>
			<div class="set_btn" v-if="menuId !== 'overview'" @click="jump_to_overview" style="bottom: 127px;"></div>
			<div class="set_btn_border" v-if="menuId === 'overview'" @click="jump_to_overview" style="bottom: 127px;"></div>
			<div class="set_menu" style="bottom: 125px;" @click="jump_to_overview">1.本月概述</div>
		</el-row>
		<el-row>
			<div class="set_btn" v-if="menuId !== 'progress'" @click="jump_to_progress" style="bottom: 107px;"></div>
			<div class="set_btn_border" v-if="menuId === 'progress'" @click="jump_to_progress" style="bottom: 107px;"></div>
			<div class="set_menu" style="bottom: 105px;" @click="jump_to_progress">2.进度情况</div>
		</el-row>
		<el-row>
			<div class="set_btn" v-if="menuId !== 'quality'" @click="jump_to_quality" style="bottom: 87px;"></div>
			<div class="set_btn_border" v-if="menuId === 'quality'" @click="jump_to_quality" style="bottom: 87px;"></div>
			<div class="set_menu" style="bottom: 85px;" @click="jump_to_quality">3.质量情况</div>
		</el-row>
		<el-row>
			<div class="set_btn" v-if="menuId !== 'meet'" @click="jump_to_meet" style="bottom: 67px;"></div>
			<div class="set_btn_border" v-if="menuId === 'meet'" @click="jump_to_meet" style="bottom: 67px;"></div>
			<div class="set_menu" style="bottom: 65px;" @click="jump_to_meet">4.会议情况</div>
		</el-row>
		<el-row>
			<div class="set_btn" v-if="menuId !== 'hse'" @click="jump_to_hse" style="bottom: 45px;"></div>
			<div class="set_btn_border" v-if="menuId === 'hse'" @click="jump_to_hse" style="bottom: 45px;"></div>
			<div class="set_menu" style="bottom: 45px;" @click="jump_to_hse">5.HSE情况</div>
		</el-row>
		<el-row>
			<div class="set_btn" v-if="menuId !== 'other'" @click="jump_to_other" style="bottom: 27px;"></div>
			<div class="set_btn_border" v-if="menuId === 'other'" @click="jump_to_other" style="bottom: 27px;"></div>
			<div class="set_menu" style="bottom: 25px;" @click="jump_to_other">6.其他需要说明的问题</div>
		</el-row>
		<el-row>
			<div class="set_btn" v-if="menuId !== 'enclosure'" @click="jump_to_enclosure" style="bottom: 7px;"></div>
			<div class="set_btn_border" v-if="menuId === 'enclosure'" @click="jump_to_enclosure" style="bottom: 7px;"></div>
			<div class="set_menu" style="bottom: 5px;" @click="jump_to_enclosure">7.随月报发送的附件</div>
		</el-row>

		<el-dialog title="添加照片" :visible.sync="dialogVisible" width="30%">
			<el-form>
				<el-row>
					<el-col :span="24">
						<el-form-item label="工程编号">
							<el-select id="photoPojectNo" v-model="photoPojectNo" placeholder="请选择">
								<el-option v-for="item in subjectList" :key="item.displayValue" :label="item.projectNo"
									:value="item.displayValue">
								</el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="24">
						<el-form-item label="描述">
							<el-input type="textarea" :autosize="{ minRows: 1, maxRows: 5 }" v-model="photoContent"
								placeholder="请填写"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="24">
						<el-form-item label="照片">
							<div class="addImgBox" v-for="(item, index) in photoList">
								<el-image style="width: 100%; height: 100%" :src="item.photoPathUri" fit="cover"></el-image>
								<div class="delBox">
									<i class="el-icon-delete" @click="delPhotoImg(index)"></i>
								</div>
							</div>
							<el-upload class="addImgBox" ref="uploadTempImg" :action="action" :http-request="uploadTempImg"
								accept="image/jpeg,image/png,image/jpg" v-if="!photoList || photoList.length === 0">
								<el-image style="width: 100%; height: 100%" :src="addImg" fit="cover">
								</el-image>
							</el-upload>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button v-preventReClick @click="dialogVisible = false">取 消</el-button>
				<el-button v-preventReClick type="primary" @click="savePhotoList">确 定</el-button>
			</span>

		</el-dialog>

		<el-dialog title="添加照片" :visible.sync="meetPhotoDialogVisible" width="30%">
			<el-form>
				<el-row>
					<el-col :span="24">
						<el-form-item label="描述">
							<el-input type="textarea" :autosize="{ minRows: 1, maxRows: 5 }" v-model="meetPhotoContent"
								placeholder="请填写"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="24">
						<el-form-item label="照片">
							<div class="addImgBox" v-for="(item, index) in meetPhotoList">
								<el-image style="width: 100%; height: 100%" :src="item.photoFilePathUrl"
									fit="cover"></el-image>
								<div class="delBox">
									<i class="el-icon-delete" @click="delMeetDialogPhoto(index)"></i>
								</div>
							</div>
							<el-upload class="addImgBox" ref="uploadMeetImg" :action="action" :http-request="uploadMeetImg"
								accept="image/jpeg,image/png,image/jpg" v-if="!meetPhotoList || meetPhotoList.length === 0">
								<el-image style="width: 100%; height: 100%" :src="addImg" fit="cover">
								</el-image>
							</el-upload>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button v-preventReClick @click="meetPhotoDialogVisible = false">取 消</el-button>
				<el-button v-preventReClick type="primary" @click="saveMeetPhotoList">确 定</el-button>
			</span>
		</el-dialog>

		<!-- 质量进度照片内容修改 -->
		<el-dialog title="修改描述" :visible.sync="updateImgContentVisable" width="30%" :close-on-click-modal="false">
			<el-form>
				<el-row>
					<el-col :span="24">
						<el-form-item label="">
							<el-input type="textarea" :autosize="{ minRows: 3, maxRows: 5 }" v-model="updatePhotoContent"
								placeholder="请填写" style="width:420px"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button v-preventReClick class="btn" type="primary" size="small" @click="saveImgContent">确 定</el-button>
				<el-button v-preventReClick class="rebtn" size="small" @click="updateImgContentVisable = false">取
					消</el-button>

			</span>
		</el-dialog>
		<add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getEnclosureList"></add-or-update>
		<quality-progress-photo-select v-if="qpPhotoSelectVisible" ref="qpPhotoSelect"
			@selectItems="margeSelectItems"></quality-progress-photo-select>
	</div>
</template>
<script>
import addImg from "@/assets/images/addImg.png"
import $common from "@/utils/common.js";
import AddOrUpdate from './monthly-enclosure-add-or-update'
import QualityProgressPhotoSelect from './quality-progress-photo-select'
import Sortable from "sortablejs";
import draggable from 'vuedraggable';

export default {
	data() {
		return {
			basicVisible: false,
			addOrUpdateVisible: false,
			qpPhotoSelectVisible: false,
			enclosureList: [],
			photoFlag: 0,
			dialogVisible: false,
			meetPhotoDialogVisible: false,
			meetPhotoListIndex: 0,
			addImg: addImg,
			recNo: '',
			beginTime: '',
			action: '',
			endTime: '',
			photoList: [],
			subjectList: [],
			majorList: [],
			meetDescList: [],
			meetPhotoList: [],
			photoPojectNo: '',
			subjectNo: '',
			photoContent: '',
			meetPhotoContent: '',
			workNo: $common.getItem("workNo"),
			dataRule: {},
			dataForm: {
			},
			photoViewCol: 6,
			draggedItem: {},
			selectPhotoType: '',
			recNos: [],
			updateImgContentVisable: false,
			photoType: '',
			photoRecNo: '',
			updatePhotoContent: '',
			contentIdx: '',
			menuId: 'basic',
			nodeList: [],
			headers:[],
			addFlg: false,
			updateFlg: false,
			updateDescRecNo: '',
			qpPhotoIndex: 0,
			updateDataType: '',
			updateIndex: '',
			updateIndex1: ''
		};
	},
	components: {
		AddOrUpdate,
		QualityProgressPhotoSelect,
		draggable
	},
	mounted() {
		this.recNo = this.$route.query.recNo;
		this.getNodeInfoList();
		this.getSubjectDropDown();
		this.getEnclosureList();
		this.getMajorList();
		this.dragSort();
		this.getMonthlyInfo();
	},
	methods: {
		getCurrentDate() {
			//获取当前时间并打印
			let yy = new Date().getFullYear();
			let mm = new Date().getMonth() + 1;
			let dd = new Date().getDate();
			if(mm< 10) {
				mm = '0' + mm;
			}
			return yy + '.' + mm + '.' + dd;
		},
		onDragStart(dataType, index, index1) {
			this.updateDataType = dataType;
			this.updateIndex = index;
			this.updateIndex1 = index1;
		},
		onDragEnd(event) {
			event.preventDefault();
			// 拖拽结束后的处理逻辑
			let records = [];
			if (this.updateDataType == '010000') {
				// 1 本月概述
				records = this.dataForm.projectDesc
			} else if (this.updateDataType == '020200') {
				// 2.2 目前工程进度与节点计划分析
				records = this.dataForm.progressPlanDesc[this.updateIndex].reportDescInfos
			} else if (this.updateDataType == '020301') {
				// 2.3.1 船体结构进度情况
				records = this.dataForm.shipProgressDesc[this.updateIndex].reportDescInfos
			} else if (this.updateDataType == '020302') {
				// 2.3.2 涂装结构进度情况
				records = this.dataForm.paintingProgressList[this.updateIndex].reportDescInfos
			} else if (this.updateDataType == '020303') {
				// 2.3.3 轮机结构进度情况
				records = this.dataForm.turbineProgressDesc[this.updateIndex].reportDescInfos
			} else if (this.updateDataType == '020304') {
				// 2.3.4 电气结构进度情况
				records = this.dataForm.electricalProgressDesc[this.updateIndex].reportDescInfos
			} else if (this.updateDataType == '030400') {
				// 3.4 不一致情况
				records = this.dataForm.disAccordDesc
			} else if (this.updateDataType == '030500') {
				// 3.5 本月施工质量分析
				records = this.dataForm.constructionQualityDesc[this.updateIndex].conQualityList[this.updateIndex1].qualityDescList
			} else if (this.updateDataType == '030700') {
				// 3.7 设计修改、图纸审查（终审）落实情况
				records = this.dataForm.designEditDesc
			} else if (this.updateDataType == '050000') {
				// 5. HSE情况
				records = this.dataForm.hseInfoDesc
			}  else if (this.updateDataType == '060000') {
				// 5. 其他需要说明的问题
				records = this.dataForm.otherDesc
			} 
			this.$http({
				url: this.$store.state.httpUrl + "/business/monthlyreportdescinfo/sort",
				method: "post",
				data: this.$http.adornData({
					dataType: this.updateDataType,
					monthlyRecNo: this.recNo,
					records : records
				})
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					this.$message({
						message: "操作成功",
						type: "success",
						duration: 1500,
						onClose: () => {
							this.updateDataType = ''
							this.getMonthlyInfo();
						}
					});
				}
			});
		},
		getDescList(descList, subjectNo) {
			let list = [];
			if (descList) {
				for (let i = 0; i < descList.length; i++) {
					if (descList[i].subjectNo == subjectNo) {
						list.push(descList[i])
					}
				}
			} 
			return list;
		},
		dragSort() {
			const el = this.$refs.singleTable.$el.querySelectorAll('.el-table__body-wrapper > table > tbody')[0]
			this.sortable = Sortable.create(el, {
				onEnd: e => {	//onEnd是结束拖拽时触发，onUpdate是列表内元素顺序更新的时候触发，更多请看文末配置项
					//e.oldIndex为拖动一行原来的位置，e.newIndex为拖动后新的位置
					const targetRow = this.enclosureList.splice(e.oldIndex, 1)[0];
					this.enclosureList.splice(e.newIndex, 0, targetRow);
					let recNo = this.enclosureList[e.newIndex].recNo;//拖动行的id
					// 后端发送请求排序
					this.$http({
						url: this.$store.state.httpUrl + "/business/monthreportenclosure/sort",
						method: "POST",
						data: this.$http.adornData({
							enclosureList: this.enclosureList,
							workNo: $common.getItem("workNo"),
							detailRecNo: this.recNo
						}),
					}).then(({ data }) => {
						if (data && data.resultCode === 200) {
							this.$message({
								message: '操作成功',
								type: 'success',
								duration: 1500,
								onClose: () => {
									this.getEnclosureList()
								}
							})
						}
					});
				}
			})
		},
		/* 照片拖拽 begin */
		cardRows(photoList) {
			const rows = [];
			for (let i = 0; i < photoList.length; i += this.photoViewCol) {
				rows.push(photoList.slice(i, i + this.photoViewCol));
			}
			return rows;
		},
		dragStart(type, idxs, rowIndex, colIndex) {
			this.draggedItem = { type: type, idxs: idxs, row: rowIndex, col: colIndex };
		},
		drop(type, idxs, rowIndex, colIndex) {
			if (type !== this.draggedItem.type || idxs !== this.draggedItem.idxs) {
				return
			}
			const x = this.draggedItem.row * this.photoViewCol + this.draggedItem.col
			const y = rowIndex * this.photoViewCol + colIndex
			let photoList = []
			if (type === 'progress') {
				photoList = this.dataForm.progressPhotoRecords[idxs].photoList
			} else if (type === 'quality') {
				photoList = this.dataForm.qualityPhotoRecords[idxs].photoList
			} else if (type === 'meeting') {
				photoList = this.meetDescList[idxs].photoRecords
			}
			if (x == y) {
				return
			} else if (x < y) {
				const item = photoList.splice(x, 1)[0];
				photoList.splice(y, 0, item);
			} else {
				const item = photoList.splice(x, 1)[0];
				photoList.splice(y, 0, item);
			}

			if(photoList != undefined && photoList.length > 1) {
				// 直接保存至db
				let url = "";
				if(type === 'progress' || type === 'quality') {
					url = "/business/monthlyreportphoto/sort"
					// 质量照片和进度照片	
				} else if(type === 'meeting') {
					// 会议照片
					url = "/business/monthlyreportdescphoto/sort"
				}
				this.$http({
					url: this.$store.state.httpUrl + url,
					method: "POST",
					data: this.$http.adornData({
						records: photoList,
					}),
				}).then(({ data }) => {
					if (data && data.resultCode === 200) {
						this.$message({
							message: '操作成功',
							type: 'success',
							duration: 1500,
							onClose: () => {
								this.getMonthlyInfo();
							}
						})
					}
				});
			}


		},
		dragEnd() {
			this.draggedItem = {};
		},
		updateBasicHandle() {
			this.basicVisible = true;
		},
		cancelSaveBasicHandle() {
			this.getMonthlyInfo()
		},
		saveBasicHandle() {
			this.$http({
				url: this.$store.state.httpUrl + "/business/monthlyreportinfo/updateMonthlyBasic",
				method: 'post',
				data: this.$http.adornData({
					recNo: this.recNo,
					monthlyName: this.dataForm.monthlyName,
					projectNum: this.dataForm.projectNum,
					projectSubjectNo: this.dataForm.projectSubjectNo,
					projectDirector: this.dataForm.projectDirector,
					qualitySituation: this.dataForm.qualitySituation,
					productionSchedule: this.dataForm.productionSchedule,
				})
			}).then(({
				data
			}) => {
				if (data && data.resultCode === 200) {
					this.$message({
						message: '操作成功',
						type: 'success',
						duration: 1500,
						onClose: () => {
							this.basicVisible = false;
							this.getMonthlyInfo()
						}
					})
				}
			})
		},
		saveDescHandle(reportDesc) {
			let _url = "/business/monthlyreportdescinfo/save";
			if (reportDesc.recNo) {
				reportDesc.updUserName = $common.getItem("userName")
				_url = "/business/monthlyreportdescinfo/update";
			}
			this.$http({
				url: this.$store.state.httpUrl + _url,
				method: "post",
				data: this.$http.adornData(reportDesc)
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					this.$message({
						message: "操作成功",
						type: "success",
						duration: 1500,
						onClose: () => {
							this.getMonthlyInfo();
						}
					});
				}
			});
		},
		updateDescHandle(descRecNo) {
			this.updateFlg = true,
			this.updateDescRecNo = descRecNo
		},
		cancelDescHandle() {
			this.getMonthlyInfo();
		},
		deleteDescHandle(descRecNo) {
			// 直接db修改
			this.$confirm(`确定对选中的数据进行删除操作?`, '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$http({
					url: this.$store.state.httpUrl + "/business/monthlyreportdescinfo/delete/" + descRecNo,
					method: "post",
					data: {},
				}).then(({ data }) => {
					if (data && data.resultCode === 200) {
						this.$message({
							message: '操作成功',
							type: 'success',
							duration: 1500,
							onClose: () => {
								this.getMonthlyInfo();
							}
						})
					} 
				});
			})
		},
		// delImg(type, idxs, rowIndex, colIndex) {
		// 	const index = rowIndex * this.photoViewCol + colIndex
		// 	let photoList = []
		// 	if (type === 'progress') {
		// 		photoList = this.dataForm.progressPhotoRecords[idxs].photoList
		// 	} else if (type === 'quality') {
		// 		photoList = this.dataForm.qualityPhotoRecords[idxs].photoList
		// 	} else if (type === 'meeting') {
		// 		photoList = this.meetDescList[idxs].photoRecords
		// 	}
		// 	photoList.splice(index, 1);
		// },
		delImg(recNo) { 
			this.$confirm(`确定对选中的数据进行删除操作?`, '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$http({
					url: this.$store.state.httpUrl + "/business/monthlyreportphoto/delete/" + recNo,
					method: "post",
					data: {},
				}).then(({ data }) => {
					if (data && data.resultCode === 200) {
						this.$message({
							message: '操作成功',
							type: 'success',
							duration: 1500,
							onClose: () => {
								this.getMonthlyInfo();
							}
						})
					} 
				});
			})
		},
		updateImg(val) {
			this.recNos.push(val)
		},
		canelUpdateImg(val) {
			let colnum = this.recNos.length
			for (let i = 0; i < colnum; i++) {
				if (this.recNos[i] == val) {
					this.recNos.splice(i, 1)
				}
			}
		},
		// updateImgContent(type, idxs, val, photoContent) {
		// 	this.updateImgContentVisable = true;
		// 	this.photoType = type;
		// 	this.photoRecNo = val;
		// 	this.updatePhotoContent = photoContent;
		// 	this.contentIdx = idxs;
		// },
		updateImgContent(recNo, photoContent) {
			this.updateImgContentVisable = true;
			this.photoRecNo = recNo;
			this.updatePhotoContent = photoContent;
		},
		saveImgContent() {
			// 直接更新到db
			let _url = "/business/monthlyreportphoto/updatePhotoContent";
			this.$http({
				url: this.$store.state.httpUrl + _url,
				method: "post",
				data: this.$http.adornData({
					recNo: this.photoRecNo,
					photoRemark: this.updatePhotoContent
				})
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					this.$message({
						message: '操作成功',
						type: 'success',
						duration: 1500,
						onClose: () => {
							this.updateImgContentVisable = false
							this.getMonthlyInfo()
						}
					})
				}
			});
			// let idxs = this.contentIdx
			// let photoList = []
			// if (this.photoType === 'progress') {
			// 	photoList = this.dataForm.progressPhotoRecords[idxs].photoList
			// } else if (this.photoType === 'quality') {
			// 	photoList = this.dataForm.qualityPhotoRecords[idxs].photoList
			// }
			// for (var i = 0; i < photoList.length; i++) {
			// 	if (photoList[i].recNo === this.photoRecNo) {
			// 		photoList[i].photoContent = this.updatePhotoContent
			// 		break
			// 	}
			// }
			// this.updateImgContentVisable = false;
		},
		/* 照片拖拽 end */
		downloadHandle(url, filename) {
			filename = this.getSuffix(url, filename)
			this.getBlob(url, (blob) => {
				this.saveAs(blob, filename)
			})
		},
		getSuffix(url, filename) {
			let lastIndex = url.lastIndexOf(".");
			return filename + url.substr(lastIndex)
		},
		getBlob(url, cb) {
			var xhr = new XMLHttpRequest()
			xhr.open('GET', url, true)
			xhr.responseType = 'blob'
			xhr.onload = function () {
				if (xhr.status === 200) {
					cb(xhr.response)
				}
			}
			xhr.send()
		},
		saveAs(blob, filename) {
			if (window.navigator.msSaveOrOpenBlob) {
				navigator.msSaveBlob(blob, filename)
			}
			else {
				var link = document.createElement('a')
				var body = document.querySelector('body')

				link.href = window.URL.createObjectURL(blob)
				link.download = filename

				link.style.display = 'none'
				body.appendChild(link)

				link.click()
				body.removeChild(link)
				window.URL.revokeObjectURL(link.href)
			}
		},
		objectSpanMethod({ row, column, rowIndex, columnIndex }) {
			if (columnIndex === 0) {
				// this.tableData  修改
				const _row = (this.flitterProjectNo(this.dataForm.shipProgressList).one)[rowIndex];
				const _col = _row > 0 ? 1 : 0;
				return {
				rowspan: _row,
				colspan: _col
				};
			} 
		},
		flitterProjectNo (arr) {
			let spanOneArr = []
			let concatOne = 0
			arr.forEach((item, index) => {
				if (index === 0) {
				spanOneArr.push(1);
				} else {
				//name 修改
				if (item.projectNo=== arr[index - 1].projectNo) { //第一列需合并相同内容的判断条件
					spanOneArr[concatOne] += 1;
					spanOneArr.push(0);
				} else {
					spanOneArr.push(1);
					concatOne = index;
				};
				}
			});
			return {
				one: spanOneArr,
			}
		},
		delPhotoImg(index) {
			this.photoList.splice(index, 1);
		},
		editHandle(id) {
			this.addOrUpdateVisible = true
			this.$nextTick(() => {
				this.$refs.addOrUpdate.init(id, this.recNo)
			})
		},
		handleProgressPhotoSelect(progressPhotoRecord, index) {
			this.selectPhotoType = '2'
			this.qpPhotoSelectVisible = true
			let photoList = progressPhotoRecord.photoList;
			this.qpPhotoIndex = index
			// 获取现场照片来源ids
			let recNos =''
			if(photoList != undefined && photoList.length > 0) {
				for(var i = 0; i < photoList.length; i++) {
					recNos = recNos + photoList[i].sourceId;
					if(i < photoList.length - 1) {
						recNos = recNos + ','
					}
				}	
			}
			this.$nextTick(() => {
				this.$refs.qpPhotoSelect.init("2", this.dataForm.beginTime, this.dataForm.endTime, recNos, progressPhotoRecord.subjectNo)
			})
		},
		handleQualityPhotoSelect(qualityPhotoRecord, index) {
			this.selectPhotoType = '1'
			this.qpPhotoSelectVisible = true
			let photoList = qualityPhotoRecord.photoList;
			this.qpPhotoIndex = index
			// 获取现场照片来源ids
			let recNos =''
			if(photoList != undefined && photoList.length > 0) {
				for(var i = 0; i < photoList.length; i++) {
					recNos = recNos + photoList[i].sourceId;
					if(i < photoList.length - 1) {
						recNos = recNos + ','
					}
				}	
			}
			this.$nextTick(() => {
				this.$refs.qpPhotoSelect.init("1", this.dataForm.beginTime, this.dataForm.endTime, recNos, qualityPhotoRecord.subjectNo)
			})
		},
		margeSelectItems(items) {
			let records = []
			let maxSort = 0;
			let photoList = []
			if (this.selectPhotoType === '2') {
				photoList = this.dataForm.progressPhotoRecords[this.qpPhotoIndex].photoList
			} else if (this.selectPhotoType === '1') {
				photoList = this.dataForm.qualityPhotoRecords[this.qpPhotoIndex].photoList

			}
			if(photoList != undefined && photoList.length > 0) {
				for(var i = 0; i < photoList.length; i++) {
					if(photoList[i].sort > maxSort) {
						maxSort = photoList[i].sort
					}
				}
			}
			if(items != undefined && items.length > 0) {
				for(var i = 0; i < items.length; i++) {
					let progressPhotot = {
						detailRecNo: this.dataForm.monthlyDetailRecNo,
						photoKind: this.selectPhotoType,
						subjectNo: items[i].subjectNo,
						sort: maxSort + 1 + i,
						photoPath: items[i].photoFilePath,
						thumbnailPath: items[i].thumbnailPath,
						sourceId: items[i].recNo,
						photoRemark:  items[i].photoContent,
						major: items[i].majorCode,
						photoPathUri: items[i].photoPathUri
					}
					records.push(progressPhotot)
				}
			}
			this.saveBatchMonthlyQPPhoto(records);

			// let noSubject = true;
			// // 循环处理选择的图片
			// for (var selectItem of items) {
			// 	for (var record of records) {
			// 		// 相同的标的物
			// 		if (record.subjectNo == selectItem.subjectNo) {
			// 			let exist = false;
			// 			for (var photo of record.photoList) {
			// 				if (photo.photoPathUri == selectItem.photoPathUri) {
			// 					exist = true
			// 					break
			// 				}
			// 			}
			// 			if (!exist) {
			// 				record.photoList.push(selectItem)
			// 				break
			// 			}
			// 		}
			// 	}
			// }
		},
		saveBatchMonthlyQPPhoto(records) {
			let _url = "/business/monthlyreportphoto/saveBatch";
			this.$http({
				url: this.$store.state.httpUrl + _url,
				method: "post",
				data: this.$http.adornData({
					records: records
				})
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					this.$message({
						message: "操作成功",
						type: "success",
						duration: 1500,
						onClose: () => {
							this.qpPhotoSelectVisible = false;
							this.getMonthlyInfo();
						}
					});
				}
			});
		},
		deleteHandle(id) {
			this.$confirm(`确定对选中的数据进行删除操作?`, '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$http({
					url: this.$store.state.httpUrl + "/business/monthreportenclosure/delete",
					method: "POST",
					data: this.$http.adornData([id], false),
				}).then(({ data }) => {
					if (data && data.resultCode === 200) {
						this.$message({
							message: '操作成功',
							type: 'success',
							duration: 1500,
							onClose: () => {
								this.getEnclosureList()
							}
						})
					} else {
						this.$message.error(data.msg)
					}
				});
			})
		},
		getEnclosureList() {
			this.$http({
				url: this.$store.state.httpUrl + `/business/monthreportenclosure/listByMonthlyId`,
				method: 'get',
				params: {
					workNo: $common.getItem("workNo"),
					detailRecNo: this.recNo
				}
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					// this.enclosureList = data.body
					let enclosureFiles = []
                    if(data.body != undefined && data.body.length > 0) {
                        for(var i = 0; i < data.body.length; i++) {
                            data.body[i].fileName = '附件' + (i + 1 ) + "：" + data.body[i].fileName
                            enclosureFiles.push(data.body[i]);
                        }
                    }
                    this.enclosureList = enclosureFiles;
				}
			})
		},
		delMeetDialogPhoto(index) {
			this.meetPhotoList.splice(index, 1);
		},
		addPhoto(flag) {
			this.photoList = [];
			this.photoContent = '';
			this.dialogVisible = true;
			this.photoFlag = flag;
		},
		addMeetingDescPhoto(idx) {
			this.meetPhotoListIndex = idx
			this.meetPhotoList = [];
			this.meetPhotoContent = '';
			this.meetPhotoDialogVisible = true;
		},
		delMeetDesc(index) {
			this.meetDescList.splice(index, 1);
		},
		uploadTempImg(param) {
			const formData = new FormData();
			formData.append('file', param.file);
			this.$refs.uploadTempImg.clearFiles();
			this.$http.post(
				this.$store.state.httpUrl + "/file/upload/" + $common.getItem("workNo") + "/95/0",
				formData,
				{
					headers: { 'Content-Type': 'multipart/form-data' }
				},
			).then(res => {
				res = res.data
				if (res.resultCode == 200) {
					this.photoList.push({
						photoPath: res.body.fileSavePath,
						photoPathUri: res.body.fileUri
					});
				} else {
					this.$message.info('文件上传失败')
				}
			})
		},
		uploadMeetImg(param) {
			const formData = new FormData();
			formData.append('file', param.file);
			this.$refs.uploadMeetImg.clearFiles();
			this.$http.post(
				this.$store.state.httpUrl + "/file/upload/" + $common.getItem("workNo") + "/95/0",
				formData,
				{
					headers: { 'Content-Type': 'multipart/form-data' }
				},
			).then(res => {
				res = res.data
				if (res.resultCode === 200) {
					this.meetPhotoList.push({
						photoFilePath: res.body.fileSavePath,
						photoFilePathUrl: res.body.fileUri,
						thumbnailPath: res.body.thumbnailPath
					});
				} else {
					this.$message.info('文件上传失败')
				}
			})
		},
		getMajorList() {
			this.$http({
				url: this.$store.state.httpUrl + "/common/getMajorByWorkNo/" + $common.getItem("workNo"),
				method: "get",
				params: {},
			}).then(({
				data
			}) => {
				if (data && data.resultCode === 200) {
					this.majorList = data.body;
				}
			});
		},
		getProjectCode(projectNo) {
			for (var item in this.subjectList) {
				if (this.subjectList[item].displayValue == projectNo) {
					return this.subjectList[item].projectNo
				}
			}
		},
		savePhotoList() {
			if (!this.photoPojectNo) {
				this.$message.error("请选择工程编号");
				return;
			}
			if (!this.photoContent) {
				this.$message.error('请填写照片描述');
				return;
			}
			if (!this.photoList || this.photoList.length === 0) {
				this.$message.error('请选择上传照片');
				return;
			}

			if (this.photoFlag === 1) {
				let flag = false;
				if (this.dataForm.progressPhotoRecords && this.dataForm.progressPhotoRecords.length > 0) {
					for (var i = 0; i < this.dataForm.progressPhotoRecords.length; i++) {
						if (this.dataForm.progressPhotoRecords[i].subjectNo == this.photoPojectNo) {
							flag = true;
							let item = {
								photoContent: this.photoContent,
								photoFilePath: this.photoList[0].photoPath,
								photoPathUri: this.photoList[0].photoPathUri,
								subjectNo: this.photoPojectNo
							};
							this.dataForm.progressPhotoRecords[i].photoList.push(item);
							break;
						}
					}
				}

				if (!flag) {
					let photoList = [];
					let progressPhotoRecord = {
						photoContent: this.photoContent,
						photoFilePath: this.photoList[0].photoPath,
						photoPathUri: this.photoList[0].photoPathUri,
						subjectNo: this.photoPojectNo
					}
					photoList.push(progressPhotoRecord);
					let item = {
						projectNo: this.getProjectCode(this.photoPojectNo),
						subjectNo: this.photoPojectNo,
						photoList: photoList
					};
					this.dataForm.progressPhotoRecords.push(item);
				}
			} else {
				let flag = false;
				if (this.dataForm.qualityPhotoRecords && this.dataForm.qualityPhotoRecords.length > 0) {
					for (var i = 0; i < this.dataForm.qualityPhotoRecords.length; i++) {
						if (this.dataForm.qualityPhotoRecords[i].subjectNo == this.photoPojectNo) {
							flag = true;
							let item = {
								photoContent: this.photoContent,
								photoFilePath: this.photoList[0].photoPath,
								photoFilePathUrl: this.photoList[0].photoPathUri,
								subjectNo: this.photoPojectNo
							};
							this.dataForm.qualityPhotoRecords[i].photoList.push(item);
							break;
						}
					}
				}

				if (!flag) {
					let photoList = [];
					let qualityPhotoRecord = {
						photoContent: this.photoContent,
						photoFilePath: this.photoList[0].photoPath,
						photoFilePathUrl: this.photoList[0].photoPathUri,
						subjectNo: this.photoPojectNo
					}
					photoList.push(qualityPhotoRecord);
					let item = {
						projectNo: this.getProjectCode(this.photoPojectNo),
						subjectNo: this.photoPojectNo,
						photoList: photoList
					};
					this.dataForm.qualityPhotoRecords.push(item);
				}
			}
			this.dialogVisible = false;
		},
		saveMeetPhotoList() {
			if (!this.meetPhotoContent) {
				this.$message.error('请填写照片描述');
				return;
			}
			if (!this.meetPhotoList || this.meetPhotoList.length === 0) {
				this.$message.error('请选择上传照片');
				return;
			}
			let idx = this.meetPhotoListIndex

			if (this.meetDescList[idx].photoRecords == null) {
				this.meetDescList[idx].photoRecords = []
			}
			let item = {
				photoContent: this.meetPhotoContent,
				photoFilePath: this.meetPhotoList[0].photoFilePath,
				photoFilePathUrl: this.meetPhotoList[0].photoFilePathUrl,
			};
			this.meetDescList[idx].photoRecords.push(item);

			this.meetPhotoDialogVisible = false;
		},
		addRecordDescItem() {
			let item = {
				monthlyRecNo: this.recNo,
				recNo : '',
				descContent: '',
				dataType: '010000',
				updUserName: $common.getItem("userName"),
				updDate: this.getCurrentDate(),
				workNo: $common.getItem("workNo")
			};
			this.dataForm.projectDesc.push(item);
			this.addFlg = true;
		},
		deleteRecordDescItem(index) {
			this.dataForm.projectDesc.splice(index, 1);
		},
		addPatrolRectificationItem() {
			let item = {
				subjectNo: '',
				detailRecNo: '',
				major: '',
				majorName: '',
				applyNum: '',
				alreadyModifyNum: '',
				notFinishNum: '',
				finishRate: ''
			};
			this.dataForm.patrolRectificationList.push(item);
		},
		addPaintingProgressItem(subjectNo, index) {
			let item = {
				monthlyRecNo: this.recNo,
				recNo : '',
				subjectNo: subjectNo,
				descContent: '',
				dataType: '020302',
				updUserName: $common.getItem("userName"),
				updDate: this.getCurrentDate(),
				workNo: $common.getItem("workNo")	
			};
			this.dataForm.paintingProgressList[index].reportDescInfos.push(item);
			this.addFlg = true;
		},
		addTurbineProgressDescItem(subjectNo, index) {
			let item = {
				monthlyRecNo: this.recNo,
				recNo : '',
				subjectNo: subjectNo,
				descContent: '',
				dataType: '020303',
				updUserName: $common.getItem("userName"),
				updDate: this.getCurrentDate(),
				workNo: $common.getItem("workNo")	
			};
			this.dataForm.turbineProgressDesc[index].reportDescInfos.push(item);
			this.addFlg = true;
		},
		addElectricalProgressDescItem(subjectNo, index) {
			let item = {
				monthlyRecNo: this.recNo,
				recNo : '',
				subjectNo: subjectNo,
				descContent: '',
				dataType: '020304',
				updUserName: $common.getItem("userName"),
				updDate: this.getCurrentDate(),
				workNo: $common.getItem("workNo")	
			};
			this.dataForm.electricalProgressDesc[index].reportDescInfos.push(item);
			this.addFlg = true;
		},
		addProgressPlanDescItem(subjectNo, index) {
			let item = {
				monthlyRecNo: this.recNo,
				recNo : '',
				subjectNo: subjectNo,
				descContent: '',
				dataType: '020200',
				updUserName: $common.getItem("userName"),
				updDate: this.getCurrentDate(),
				workNo: $common.getItem("workNo")	
			};
			this.dataForm.progressPlanDesc[index].reportDescInfos.push(item);
			this.addFlg = true;
		},
		deleteProgressPlanDescItem(index) {
			this.dataForm.progressPlanDesc.splice(index, 1);
		},
		addDisAccordDescItem() {
			let item = {
				monthlyRecNo: this.recNo,
				recNo : '',
				descContent: '',
				dataType: '030400',
				updUserName: $common.getItem("userName"),
				updDate: this.getCurrentDate(),
				workNo: $common.getItem("workNo")	
			};
			this.dataForm.disAccordDesc.push(item);
			this.addFlg = true;
		},
		deleteDisAccordDescItem(index) {
			this.dataForm.disAccordDesc.splice(index, 1);
		},
		addConstructionQualityDescItem(subjectNo, major, index, index1) {
			let item = {
				monthlyRecNo: this.recNo,
				recNo : '',
				subjectNo: subjectNo,
				major: major,
				descContent: '',
				dataType: '030500',
				updUserName: $common.getItem("userName"),
				updDate: this.getCurrentDate(),
				workNo: $common.getItem("workNo")	
			};
			this.dataForm.constructionQualityDesc[index].conQualityList[index1].qualityDescList.push(item);
			this.addFlg = true;
		},
		deleteConstructionQualityDescItem(index) {
			this.dataForm.constructionQualityDesc.splice(index, 1);
		},
		addReportCheckDecsItem() {
			let item = {
				descContent: '',
				dataType: '030600'
			};
			this.dataForm.reportCheckDecs.push(item);
		},
		deleteReportCheckDecsItem(index) {
			this.dataForm.reportCheckDecs.splice(index, 1);
		},
		addDesignEditDescItem() {
			let item = {
				monthlyRecNo: this.recNo,
				recNo : '',
				descContent: '',
				dataType: '030700',
				updUserName: $common.getItem("userName"),
				updDate: this.getCurrentDate(),
				workNo: $common.getItem("workNo")	
			};
			this.dataForm.designEditDesc.push(item);
			this.addFlg = true;
		},
		deleteDesignEditDescItem(index) {
			this.dataForm.designEditDesc.splice(index, 1);
		},
		addShipProgressDescItem(subjectNo, index) {
			let item = {
				monthlyRecNo: this.recNo,
				recNo : '',
				subjectNo: subjectNo,
				descContent: '',
				dataType: '020301',
				updUserName: $common.getItem("userName"),
				updDate: this.getCurrentDate(),
				workNo: $common.getItem("workNo")	
			};
			this.dataForm.shipProgressDesc[index].reportDescInfos.push(item);
			this.addFlg = true;
		},
		deleteShipProgressDescItem(index) {
			this.dataForm.shipProgressDesc.splice(index, 1);
		},
		addTestRecordItem() {
			let item = {
				subjectNo: '',
				major: '',
				majorName: '',
				testConclusionCount: '',
				testConclusionWhereCount: '',
				recheckAfterCorrectionCount: '',
				testConclusionRefuseCount: '',
				testConclusionRate: ''
			};
			this.dataForm.testRecordList.push(item);
		},
		deleteTestRecordItem(index) {
			this.dataForm.testRecordList.splice(index, 1);
		},
		addSectionalProgress() {
			let item = {
				projectNo: '',
				totalNum: 0,
				upNum: 0,
				checkNum: 0,
				paintingNum: 0,
				upShipNum: 0
			};
			this.dataForm.sectionalItemRecords.push(item);
		},
		deleteSectionalItem(index) {
			this.dataForm.sectionalItemRecords.splice(index, 1);
		},
		addMooringItem() {
			let item = {
				projectNo: '',
				testItemNum: 0,
				testFinishItemNum: 0,
				shipFinishItemNum: 0,
				turbineFinishItemNum: 0,
				electricalFinishItemNum: 0
			};
			this.dataForm.mooringTestItemRecords.push(item);
		},
		deleteMooringItem(index) {
			this.dataForm.mooringTestItemRecords.splice(index, 1);
		},
		addHseInfoDescItem() {
			let item = {
				monthlyRecNo: this.recNo,
				recNo : '',
				descContent: '',
				dataType: '050000',
				updUserName: $common.getItem("userName"),
				updDate: this.getCurrentDate(),
				workNo: $common.getItem("workNo")	
			};
			this.dataForm.hseInfoDesc.push(item);
			this.addFlg = true;
		},
		deleteHseInfoDescItem(index) {
			this.dataForm.hseInfoDesc.splice(index, 1);
		},
		addMeetingDescItem() {
			let item = {
				dataType: '040000',
				descContent: '',
				photoRecords: []
			};
			this.meetDescList.push(item)
		},
		deleteMeetingDescItem(index) {
			this.meetDescList.splice(index, 1);
		},
		addOtherDescItem() {
			let item = {
				monthlyRecNo: this.recNo,
				recNo : '',
				descContent: '',
				dataType: '060000',
				updUserName: $common.getItem("userName"),
				updDate: this.getCurrentDate(),
				workNo: $common.getItem("workNo")	
			};
			this.dataForm.otherDesc.push(item);
			this.addFlg = true;
		},
		deleteOtherDescItem(index) {
			this.dataForm.otherDesc.splice(index, 1);
		},
		goBack() {
			this.$router.back()
		},
		getNodeInfoList() {
			this.$http({
				url: this.$store.state.httpUrl + "/business/projectprogressnoderecord/list/" + $common.getItem("workNo"),
				method: 'get',
				params: this.$http.adornParams({
				})
			}).then(({
				data
			}) => {
				if (data && data.resultCode === 200) {
				this.nodeList = data.body.records;
				this.headers = data.body.headers;
				}
			})
		},
		getMonthlyInfo() {
			this.$http({
				url: this.$store.state.httpUrl + "/business/monthlyreportinfo/getMonthlyById",
				method: "get",
				params: {
					workNo: this.workNo,
					recNo: this.recNo
				},
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					this.dataForm = data.body;
					if (this.dataForm.meetingDesc) {
						this.meetDescList = this.dataForm.meetingDesc
					}
					// constructionQualityDesc
					let  constructionQualityDescList = []
					for (let i = 0; i < this.subjectList.length; i++) {
						let subjectNo = this.subjectList[i].displayValue
						let constructionQuality = {
							subjectNo : subjectNo,
							subjectName : this.subjectList[i].displayName,
							conQualityList : []
						}
						constructionQualityDescList.push(constructionQuality)
						for (let j = 0; j < this.majorList.length; j++) {
							let major = this.majorList[j].displayValue
							let conQualityList = constructionQualityDescList[i].conQualityList
							let conQuality = {
								major : this.majorList[j].displayValue,
								majorName: this.majorList[j].displayName,
								qualityDescList: []
							}
							conQualityList.push(conQuality);
							for (let k = 0; k < this.dataForm.constructionQualityDesc.length; k++) {
								if(this.dataForm.constructionQualityDesc[k].subjectNo === subjectNo
									&& this.dataForm.constructionQualityDesc[k].major === major) {
										constructionQualityDescList[i].conQualityList[j].qualityDescList.push(this.dataForm.constructionQualityDesc[k])
								}
							}
						}
					}
					this.dataForm.constructionQualityDesc = constructionQualityDescList

					// 2.2 目前工程进度与节点计划分析
					let progressPlanDescList = []
					for (let i = 0; i < this.subjectList.length; i++) {
						let subjectNo = this.subjectList[i].displayValue
						let progressPlanDesc = {
							subjectNo : subjectNo,
							subjectName : this.subjectList[i].displayName,
							reportDescInfos : [],
						}
						progressPlanDescList.push(progressPlanDesc)
						for (let k = 0; k < this.dataForm.progressPlanDesc.length; k++) {
							if(this.dataForm.progressPlanDesc[k].subjectNo === subjectNo) {
								progressPlanDescList[i].reportDescInfos.push(this.dataForm.progressPlanDesc[k])

							}
						}
					}
					this.dataForm.progressPlanDesc = progressPlanDescList;

					// 2.3.1 船体结构进度情况
					let shipProgressDescList = []
					for (let i = 0; i < this.subjectList.length; i++) {
						let subjectNo = this.subjectList[i].displayValue
						let shipProgressDesc = {
							subjectNo : subjectNo,
							subjectName : this.subjectList[i].displayName,
							reportDescInfos : [],
						}
						shipProgressDescList.push(shipProgressDesc)
						for (let k = 0; k < this.dataForm.shipProgressDesc.length; k++) {
							if(this.dataForm.shipProgressDesc[k].subjectNo === subjectNo) {
								shipProgressDescList[i].reportDescInfos.push(this.dataForm.shipProgressDesc[k])

							}
						}
					}
					this.dataForm.shipProgressDesc = shipProgressDescList;

					// 2.3.2 涂装工程进度情况
					let paintingProgressList = []
					for (let i = 0; i < this.subjectList.length; i++) {
						let subjectNo = this.subjectList[i].displayValue
						let paintingProgress = {
							subjectNo : subjectNo,
							subjectName : this.subjectList[i].displayName,
							reportDescInfos : [],
						}
						paintingProgressList.push(paintingProgress)
						for (let k = 0; k < this.dataForm.paintingProgressList.length; k++) {
							if(this.dataForm.paintingProgressList[k].subjectNo === subjectNo) {
								paintingProgressList[i].reportDescInfos.push(this.dataForm.paintingProgressList[k])

							}
						}
					}
					this.dataForm.paintingProgressList = paintingProgressList;

					// 2.3.3 轮机工程进度情况
					let turbineProgressDescList = []
					for (let i = 0; i < this.subjectList.length; i++) {
						let subjectNo = this.subjectList[i].displayValue
						let turbineProgressDesc = {
							subjectNo : subjectNo,
							subjectName : this.subjectList[i].displayName,
							reportDescInfos : [],
						}
						turbineProgressDescList.push(turbineProgressDesc)
						for (let k = 0; k < this.dataForm.turbineProgressDesc.length; k++) {
							if(this.dataForm.turbineProgressDesc[k].subjectNo === subjectNo) {
								turbineProgressDescList[i].reportDescInfos.push(this.dataForm.turbineProgressDesc[k])

							}
						}
					}
					this.dataForm.turbineProgressDesc = turbineProgressDescList;

					// 2.3.4 电气工程进度情况
					let electricalProgressDescList = []
					for (let i = 0; i < this.subjectList.length; i++) {
						let subjectNo = this.subjectList[i].displayValue
						let electricalProgressDesc = {
							subjectNo : subjectNo,
							subjectName : this.subjectList[i].displayName,
							reportDescInfos : [],
						}
						electricalProgressDescList.push(electricalProgressDesc)
						for (let k = 0; k < this.dataForm.electricalProgressDesc.length; k++) {
							if(this.dataForm.electricalProgressDesc[k].subjectNo === subjectNo) {
								electricalProgressDescList[i].reportDescInfos.push(this.dataForm.electricalProgressDesc[k])

							}
						}
					}
					this.dataForm.electricalProgressDesc = electricalProgressDescList;

					this.basicVisible = false;
					this.addFlg = false;
					this.updateFlg = false;
					this.updateDescRecNo= '';
				}
			});
		},
		delMeetPhoto(index1, index2) {
			this.meetDescList[index1].photoRecords.splice(index2, 1);
		},
		getSubjectDropDown() {
			this.$http({
				url: this.$store.state.httpUrl + "/common/getSubjectDropDown/" + $common.getItem("workNo"),
				method: "get",
				params: {},
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					this.subjectList = data.body;
				}
			});
		},
		dataFormSubmit() {
			let _url = "/business/monthlyreportinfo/saveMonthlyReport";
			this.dataForm.recNo = this.recNo;
			this.dataForm.meetingDesc = this.meetDescList
			this.$http({
				url: this.$store.state.httpUrl + _url,
				method: "post",
				data: this.$http.adornData(this.dataForm)
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					this.$message({
						message: "操作成功",
						type: "success",
						duration: 1500,
						onClose: () => {
							this.$router.push({ name: 'monthly' });
						}
					});
				} else {
					this.$message.error(data.msg);
				}
			});
		},
		jump_to_basic() {
			this.menuId = 'basic'
			this.$refs["basic"].scrollIntoView({
				behavior: "smooth",
				block: "start"
			});
		},
		jump_to_overview() {
			this.menuId = 'overview'
			this.$refs["overview"].scrollIntoView({
				behavior: "smooth",
				block: "start"
			});
		},
		jump_to_progress() {
			this.menuId = 'progress'
			this.$refs["progress"].scrollIntoView({
				behavior: "smooth",
				block: "start"
			});
		},
		jump_to_quality() {
			this.menuId = 'quality'
			this.$refs["quality"].scrollIntoView({
				behavior: "smooth",
				block: "start"
			});
		},
		jump_to_hse() {
			this.menuId = 'hse'
			this.$refs["hse"].scrollIntoView({
				behavior: "smooth",
				block: "start"
			});
		},
		jump_to_meet() {
			this.menuId = 'meet'
			this.$refs["meet"].scrollIntoView({
				behavior: "smooth",
				block: "start"
			});
		},
		jump_to_other() {
			this.menuId = 'other'
			this.$refs["other"].scrollIntoView({
				behavior: "smooth",
				block: "start"
			});
		},
		jump_to_enclosure() {
			this.menuId = 'enclosure'
			this.$refs["enclosure"].scrollIntoView({
				behavior: "smooth",
				block: "start",
			});
		},
		tableRowClassName({ row, rowIndex }) {
			return $common.tableScnenRowClassName({ row, rowIndex });
		},
		flitterSubjectNo (arr) {
			let spanOneArr = []
			let concatOne = 0
			arr.forEach((item, index) => {
				if (index === 0) {
				spanOneArr.push(1);
				} else {
				//name 修改
				if (item.subjectName === arr[index - 1].subjectName) { //第一列需合并相同内容的判断条件
					spanOneArr[concatOne] += 1;
					spanOneArr.push(0);
				} else {
					spanOneArr.push(1);
					concatOne = index;
				};
				}
			});
			return {
				one: spanOneArr,
			}
		},
		flitterData (arr) {
			let spanOneArr = []
			let concatOne = 0
			arr.forEach((item, index) => {
				if (index === 0) {
					spanOneArr.push(1);
				} else {
				//name 修改
				if (item.title === arr[index - 1].title) { //第一列需合并相同内容的判断条件
					spanOneArr[concatOne] += 1;
					spanOneArr.push(0);
				} else {
					spanOneArr.push(1);
					concatOne = index;
				};
				}
			});
			return {
				one: spanOneArr,
			}
		},
		objectNodeSpanMethod ({ row, column, rowIndex, columnIndex }) {
			if (columnIndex === 0) {
				// this.tableData  修改
				const _row = (this.flitterSubjectNo(this.nodeList).one)[rowIndex];
				const _col = _row > 0 ? 1 : 0;
				return {
				rowspan: _row,
				colspan: _col
				};
			} else if(columnIndex === 1){
				// this.tableData  修改
				const _row = (this.flitterData(this.nodeList).one)[rowIndex];
				const _col = _row > 0 ? 1 : 0;
				return {
				rowspan: _row,
				colspan: _col
				};
			}
		},
	}
};
</script>
<style lang="scss" scoped="scoped">
.el-form-item {
	margin-bottom: 5px !important;
}

.addImgBox {
	width: 136px;
	height: 136px;
	display: inline-block;
	vertical-align: middle;
	margin-right: 10px;
}

.addImgBox200 {
	width: 200px;
	height: 200px;
	display: inline-block;
	vertical-align: middle;
	margin-right: 10px;
}

.elCardTitle {
	font-size: 18px;
	font-weight: bold;
	color: #00428e;
	margin-bottom: 20px;
	padding-bottom: 20px;
	border-bottom: 1px solid #00428e;
}

.selItemInput.descAreaLg {
	width: 1030px !important;
}

.selItemInput.descAreaLg1 {
	width: 430px !important;
}

.selItemInput.descAreaLg2 {
	width: 230px !important;
}

.time {
	font-size: 13px;
	color: #999;
}

.bottom {
	margin-top: 3px;
	line-height: 12px;
	vertical-align: bottom;
}

.button {
	padding: 0;
	float: right;
}

.image {
	width: 100%;
	height: 200px;
	margin-top: 5px;
	display: block;
}

.clearfix:after {
	display: table;
	content: "";
}

.clearfix:after {
	clear: both
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
}

.itemLabelspan {
	font-weight: bold;
	color: #606266;
	margin-right: 10px;
}

.itemLabeltext {
	color: #606266;
	margin-right: 10px;
}

.t-column-ellipsis {
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 3;
	overflow: hidden;
	padding-bottom: 10px;
	height: 50px;
}

.item::v-deep .el-form-item__label {
	color: #00428E !important;
	margin-right: 10px !important;
	font-weight: bold !important;
	background-color: #E1F1FF;
	white-space: nowrap !important;
	border-right: solid #dadada 1px;
}

::v-deep .el-form-item {
	display: flex !important;
	align-items: stretch !important;
	margin-bottom: 0px !important;
}

.border-form .el-row.set_border {
	// border-bottom: solid #dadada 1px;
	// border-left: solid #dadada 1px;
	// border-right: solid #dadada 1px;
	border: solid #dadada 1px;
}

.border-form .el-row.set_border:first-child {
	border-top: solid #dadada 2px;
}

.border-form .el-row.set_border .col-set_border {
	border-right: solid #dadada 1px;
}

.btn1 {
	bottom: 13px;
	margin-left: -60px;
	position: fixed;
}

.rebtn1 {
	bottom: 13px;
	position: fixed;
}

.set_bg {
	width: 65%;
	height: 50px;
	bottom: 6px;
	position: fixed;
	left: 20%;
	background-color: #f7f1f1;
}

.set_menu {
	position: fixed;
	cursor: pointer;
	left: 92%;
	font-size: smaller;
	color: #6d6c6c;
}

.set_btn {
	left: 91%;
	position: fixed;
	width: 9px;
	height: 9px;
	background-color: transparent;
	background: white;
	border-radius: 50%;
	cursor: pointer;
	border: 2px solid #81D3F8;
}

.set_btn_border {
	left: 91%;
	position: fixed;
	width: 9px;
	height: 9px;
	background-color: transparent;
	background: #81D3F8;
	border-radius: 50%;
	cursor: pointer;
	border: 2px solid #81D3F8;
}

.node-container {
	display: flex;
	color: #1c1c1c;
}

.node-left-container {
	// border: 1px #DADADA solid;
	border-right: 0px;
	width: 70px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: 20px;
}

.node-right-container {
	border-right: 1px #DADADA solid;
	border-bottom: 1px #DADADA solid;
	display: flex;
	flex-direction: column;
}

.node-right-container-row {
	display: flex;
}

.node-content-title1 {
	border: 1px #DADADA solid;
	border-right: 0px;
	border-bottom: 0px;
	width: 100px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: rgb(211, 230, 248);
	height: 30px
}

.node-content-title2 {
	border: 1px #DADADA solid;
	border-right: 0px;
	border-bottom: 0px;
	width: 100px;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 30px
}

.node-content-title3 {
	border: 1px #DADADA solid;
	border-right: 0px;
	border-bottom: 0px;
	width: 100px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: rgb(240, 240, 33);
	height: 30px
}

.node-content-body {
	border: 1px #DADADA solid;
	border-right: 0px;
	border-bottom: 0px;
	width: 120px;
	height: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 30px
}

.node-content-body5 {
	border: 1px #DADADA solid;
	border-right: 0px;
	border-bottom: 0px;
	width: 230px;
	height: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 30px
}

.node-content-body1 {
	border: 1px #DADADA solid;
	border-right: 0px;
	border-bottom: 0px;
	width: 120px;
	height: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: rgb(211, 230, 248);
	height: 30px
}

.node-content-body4 {
	border: 1px #DADADA solid;
	border-right: 0px;
	border-bottom: 0px;
	width: 230px;
	height: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: rgb(211, 230, 248);
	height: 30px
}

.node-content-body3 {
	border: 1px #DADADA solid;
	border-right: 0px;
	border-bottom: 0px;
	width: 120px;
	height: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: rgb(240, 240, 33);
	height: 30px
}

.node-content-body6 {
	border: 1px #DADADA solid;
	border-right: 0px;
	border-bottom: 0px;
	width: 230px;
	height: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: rgb(240, 240, 33);
	height: 30px
}

.node-content-title-container {
	display: flex;
}

.node-content-title-container-left {
	border: 1px #DADADA solid;
	border-right: 0px;
	border-bottom: 0px;
	width: 100px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #ebeae8;
}

.node-content-title-container-right {
	display: flex;
	flex-direction: column;
}

.node-content-title-container-right-row {
	display: flex;
	flex-direction: row;
	background-color: #ebeae8;
}
.set_lewi {
	font-weight: bold;
	color: #606266;
	margin-left: -10px;
}

.el-icon-edit-outline {
	color: #169BD5;
	font-size: large;
	margin-left:20px;
	margin-top:138px;
	margin-right:2px;
	cursor: pointer
}

.el-icon-finished {
	color: #169BD5;
	font-size: large;
	margin-left:20px;
	margin-top:148px;
	margin-right:2px;
	cursor: pointer
}

.el-icon-takeaway-box {
	color: #169BD5;
	font-size: 16px;
	margin-left:20px;
	margin-right:2px;
	cursor: pointer
}

.itemLabelspanDetail {
	font-weight: bold;
	color: #606266;
	margin-right: 185px;
}

.itemLabelspanDetail1 {
	font-weight: bold;
	color: #606266;
	margin-right: 157px;
}

.desc_zw {
	font-weight: 400;
    font-style: normal;
    font-size: 13px;
	color: white;
}

.desc_zw_bc {
	font-weight: 400;
    font-style: normal;
    font-size: 13px;
	color: #e1f1ff;
}

::v-deep .desc_input1 .el-textarea__inner {
    // padding-right: 50px !important;
     border: 0px; 
	 color: black;
	 resize: none;
}

::v-deep(.sp-input1 .el-input__inner) {
	width: 335% !important;
}

.set_span {
	display: block; /* 将span转换为块级元素 */
	max-width: 275px; /* 设置固定长度 */
	white-space: nowrap; /* 不换行 */
	overflow: hidden; /* 超出部分隐藏 */
	text-overflow: ellipsis; /* 超出部分显示省略号 */
	cursor: default; /* 将鼠标光标设置为默认样式 */
}

.set_span1 {
	display: block; /* 将span转换为块级元素 */
	max-width: 710px; /* 设置固定长度 */
	white-space: nowrap; /* 不换行 */
	overflow: hidden; /* 超出部分隐藏 */
	text-overflow: ellipsis; /* 超出部分显示省略号 */
	cursor: default; /* 将鼠标光标设置为默认样式 */
}
</style>
